const Gift = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M15.4941 22.3027H7.82747C4.54997 22.3027 3.27539 21.0282 3.27539 17.7507V10.084C3.27539 9.69107 3.60122 9.36523 3.99414 9.36523H19.3275C19.7204 9.36523 20.0462 9.69107 20.0462 10.084V17.7507C20.0462 21.0282 18.7716 22.3027 15.4941 22.3027ZM4.71289 10.8027V17.7507C4.71289 20.2232 5.35497 20.8652 7.82747 20.8652H15.4941C17.9666 20.8652 18.6087 20.2232 18.6087 17.7507V10.8027H4.71289Z" fill="url(#paint0_linear_3158_7290)" />
    <path d="M18.8766 10.8014H4.50163C2.82454 10.8014 1.86621 9.8431 1.86621 8.16602V7.20768C1.86621 5.5306 2.82454 4.57227 4.50163 4.57227H18.8766C20.5058 4.57227 21.512 5.57852 21.512 7.20768V8.16602C21.512 9.79518 20.5058 10.8014 18.8766 10.8014ZM4.50163 6.00977C3.62954 6.00977 3.30371 6.3356 3.30371 7.20768V8.16602C3.30371 9.0381 3.62954 9.36393 4.50163 9.36393H18.8766C19.72 9.36393 20.0745 9.00935 20.0745 8.16602V7.20768C20.0745 6.36435 19.72 6.00977 18.8766 6.00977H4.50163Z" fill="url(#paint1_linear_3158_7290)" />
    <path d="M11.3443 6.01083H6.05426C5.85301 6.01083 5.66134 5.92458 5.52718 5.78083C4.94259 5.13875 4.96176 4.15167 5.57509 3.53833L6.93593 2.1775C7.56843 1.545 8.61301 1.545 9.24551 2.1775L11.8522 4.78417C12.0534 4.98542 12.1205 5.30167 12.0055 5.57C11.9001 5.83833 11.6413 6.01083 11.3443 6.01083ZM6.58134 4.57333H9.61926L8.22968 3.19333C8.15301 3.11667 8.02843 3.11667 7.95176 3.19333L6.59092 4.55417C6.59092 4.56375 6.58134 4.56375 6.58134 4.57333Z" fill="url(#paint2_linear_3158_7290)" />
    <path d="M17.315 6.01083H12.025C11.7375 6.01083 11.4691 5.83833 11.3637 5.57C11.2487 5.30167 11.3158 4.995 11.517 4.78417L14.1237 2.1775C14.7562 1.545 15.8008 1.545 16.4333 2.1775L17.7941 3.53833C18.4075 4.15167 18.4362 5.13875 17.842 5.78083C17.7079 5.92458 17.5162 6.01083 17.315 6.01083ZM13.7691 4.57333H16.807C16.7975 4.56375 16.7975 4.56375 16.7879 4.55417L15.427 3.19333C15.3504 3.11667 15.2258 3.11667 15.1491 3.19333L13.7691 4.57333Z" fill="url(#paint3_linear_3158_7290)" />
    <path d="M9.71517 16.6965C9.44684 16.6965 9.16892 16.6294 8.91975 16.4952C8.3735 16.1982 8.03809 15.6327 8.03809 15.0194V10.084C8.03809 9.69107 8.36392 9.36523 8.75684 9.36523H14.5452C14.9381 9.36523 15.2639 9.69107 15.2639 10.084V15.0002C15.2639 15.6232 14.9285 16.1886 14.3823 16.4761C13.836 16.7732 13.1748 16.7348 12.6573 16.3898L11.8043 15.8148C11.7277 15.7573 11.6223 15.7573 11.536 15.8148L10.6352 16.409C10.3573 16.6007 10.0314 16.6965 9.71517 16.6965ZM9.47559 10.8027V15.0098C9.47559 15.1344 9.55225 15.1919 9.60017 15.2207C9.64809 15.2494 9.74392 15.2782 9.84934 15.2111L10.7502 14.6169C11.3156 14.2432 12.0439 14.2432 12.5998 14.6169L13.4527 15.1919C13.5581 15.259 13.6539 15.2302 13.7018 15.2015C13.7498 15.1727 13.8264 15.1152 13.8264 14.9907V10.7932H9.47559V10.8027Z" fill="url(#paint4_linear_3158_7290)" />
    <defs>
      <linearGradient id="paint0_linear_3158_7290" x1="3.27539" y1="22.3027" x2="17.0038" y2="6.68998" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00642E" />
        <stop offset="1" stopColor="#9FC500" />
      </linearGradient>
      <linearGradient id="paint1_linear_3158_7290" x1="1.86621" y1="10.8014" x2="6.12708" y2="-0.988055" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00642E" />
        <stop offset="1" stopColor="#9FC500" />
      </linearGradient>
      <linearGradient id="paint2_linear_3158_7290" x1="5.10156" y1="6.01083" x2="9.44351" y2="-0.143854" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00642E" />
        <stop offset="1" stopColor="#9FC500" />
      </linearGradient>
      <linearGradient id="paint3_linear_3158_7290" x1="11.3076" y1="6.01083" x2="15.6488" y2="-0.146026" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00642E" />
        <stop offset="1" stopColor="#9FC500" />
      </linearGradient>
      <linearGradient id="paint4_linear_3158_7290" x1="8.03809" y1="16.6965" x2="15.7998" y2="9.98494" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00642E" />
        <stop offset="1" stopColor="#9FC500" />
      </linearGradient>
    </defs>
  </svg>
)

export default Gift;