export const isDateValid = (date: string) => {
  const dateArray = date.split("/");
  if (dateArray.length !== 3) {
    return false;
  }
  const day = parseInt(dateArray[0]);
  const month = parseInt(dateArray[1]);
  const year = parseInt(dateArray[2]);
  const dateObject = new Date(year, month - 1, day);
  return (
    dateObject.getFullYear() === year &&
    dateObject.getMonth() === month - 1 &&
    dateObject.getDate() === day
  );
};

export const isAccessionDateValid = (date: string) => {
  const dateArray = date.split("/");
  if (dateArray.length !== 3) {
    return false;
  }
  const day = parseInt(dateArray[0]);
  const month = parseInt(dateArray[1]);
  const year = parseInt(dateArray[2]);
  const now = new Date();

  return (
    year > now.getFullYear() ||
    (year === now.getFullYear() && month > now.getMonth()) ||
    (year === now.getFullYear() &&
      month === now.getMonth() &&
      day >= now.getDate())
  );
};

export const adhHasMajority = (date: string) => {
  const birthday = new Date(date);
  const now = new Date();

  return now.getFullYear() - birthday.getFullYear() >= 18;
};
