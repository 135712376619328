import React from 'react';

import NextButton from "src/components/Ui/NextButton";
import AdditionalOffers from 'src/components/Ui/AdditionalOffers';
import Info from "src/assets/Info";
import ToolTip from 'src/assets/ToolTip';
import TinyStar from 'src/assets/Stars/Tiny';
import MySituationOfferModal from 'src/components/Ui/Modals/MySituationOfferModal';
import Input from 'src/components/Ui/Input';
import { formDevisMyInformationsInvalid } from 'src/services/validatorsDevis';
import { SituationState } from 'src/types/Situation';
import Select from 'src/components/Ui/Select';
import useDevis from 'src/hooks/useDevis';

import styles from './styles.module.css';

const MySituation = () => {
  const context = useDevis();

  const {
    situationState, setSituationState,
    setStep,
    maxSteps,
    setSituationStep,
    situationsDropdownValues,
    socialSecuritySchemes,
    activities,
    quotePriceYear
  } = context;

  const [modal, setModal] = React.useState(false);

  function handleNextStep() {
    if (maxSteps === 1) {
      setStep(1);
    } else {
      setSituationStep(2);
    }

    const nav = document.getElementsByTagName('nav')[0];
    nav?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: "nearest" });
    // maxSteps === 1 ? setStep(1) : setSituationStep(2)
  }

  return (
    <div className={`flex justify-center p-4 gap-12 rounded-md items-start ${styles.responsivContainer}`}>
      <div className={`flex flex-col items-start space-y-4 p-14 relative overflow-hidden ${styles.section}`}>
        <span className={styles.iconInfo}>
          <Info />
        </span>
        <span className={styles.steps}>
          1/{maxSteps}
        </span>
        <h1 className="text-xl mb-4 font-bold text-blue-900">J’indique ma situation</h1>
        <Select
          label='Résident à l’étranger *'
          value={situationState.outside}
          error={situationState.outside === ''}
          onChange={(outside) =>
            setSituationState((prevState: SituationState) => ({
              ...prevState,
              outside: outside,
              postalCode: '',
              situation: '',
              socialSecurityScheme: ''
            }))
          }
          id='outside'
          values={[
            { v: '1', l: 'Oui' },
            { v: '0', l: 'Non' }
          ]}
        />
        {situationState.outside === '0' && 
          <Input
            value={situationState.postalCode}
            error={situationState.postalCode === ''}
            onChange={(postalCode) => {
              if (!isNaN(Number(postalCode))) {
                setSituationState((prevState: SituationState) => ({
                  ...prevState,
                  postalCode: postalCode as string
                }))
              }
            }}
            id='postalCode'
            maxLength={5}
            label="Code postal *"
          />
        }
        <Input
          value={situationState.birthday}
          error={situationState.birthday === ''}
          onChange={(birthday) =>{
              setSituationState((prevState: SituationState) => ({
                ...prevState,
                birthday: birthday as string
              }))
            }
          }
          id='birthday'
          type='date'
          label='Date de naissance *'
          maxDate={new Date().toISOString().split('T')[0]}
          maxLength={10}
        />
        <Input
          value={situationState.accessionDate}
          error={situationState.accessionDate === ''}
          onChange={(accessionDate) =>
            setSituationState((prevState: SituationState) => ({
              ...prevState,
              accessionDate: accessionDate as string
            }))
          }
          id='accessionDate'
          type='date'
          minDate={new Date().toISOString().split('T')[0]}
          maxDate={quotePriceYear + '-12-31'}
          label='Date de prise en charge souhaitée *'
          maxLength={10}
        />
        <Select
          label='Vous êtes *'
          value={situationState.situation}
          error={situationState.situation === ''}
          onChange={(situation) =>
            setSituationState((prevState: SituationState) => ({
              ...prevState,
              situation,
              socialSecurityScheme: ''
            }))
          }
          id='situation'
          values={
            situationsDropdownValues && situationsDropdownValues
              .map(({ id, name }) => ({ v: id.toString(), l: name }))
          }
        />
        <Select
          label='Régime de sécurité sociale *'
          value={situationState.socialSecurityScheme}
          error={situationState.socialSecurityScheme === ''}
          onChange={(socialSecurityScheme) =>
            setSituationState((prevState: SituationState) => ({
              ...prevState,
              socialSecurityScheme
            }))
          }
          id='socialSecurityScheme'
          values={
            socialSecuritySchemes && socialSecuritySchemes
              .map(({ id, name }) => ({ v: id, l: name }))
          }
        />
        <Select
          label={'Type d\'activité *'}
          value={situationState.activity}
          error={situationState.activity === ''}
          onChange={(activity) =>
            setSituationState((prevState: SituationState) => ({
              ...prevState,
              activity
            }))
          }
          id='activity'
          values={activities && activities.map(({ id, name }) => ({ v: id, l: name }))}
        />
        <Select
          label='Sportif (-10%) *'
          value={situationState.athletic}
          error={situationState.athletic === ''}
          onChange={(athletic) =>
            setSituationState((prevState: SituationState) => ({
              ...prevState,
              athletic
            }))
          }
          id='athletic'
          values={[
            { l: 'Oui', v: 'true' },
            { l: 'Non', v: 'false' }
          ]}
          noPlaceholder
        />
        <div className="flex items-center gap-6">
          <span className={styles.tooltip}>
            <ToolTip />
          </span>
          <p className={styles.informations}>
            Un justificatif d’inscription à un club de sport devra être fourni au moment de la souscription pour bénéficier de la réduction.
            <span
              onClick={() => setModal(true)}
              className={styles.informationsCondition}>Voir les conditions</span>
          </p>
        </div>
        <div className='flex justify-end w-full'>
          <p style={{ color: '#243E69', fontSize: '12px', fontWeight: 400 }}>(*) : champs obligatoires</p>
        </div>
      </div>

      <AdditionalOffers
        image="/images/my-situation-offer.jpg"
        additionalComponent={(
          <div className={styles.reduction}>
            <div className='relative'>
              <TinyStar />
              <span className={styles.reductionText}>-10%</span>
            </div>
          </div>
        )}
        title="Membre d’un club de sports ?"
        description="Bénéficiez de -10% sur vos cotisations !"
        action={() => {
          setModal(true);
        }}
      />

      {
        modal && (
          <MySituationOfferModal
            close={() => setModal(false)}
          />
        )
      }

      <NextButton
        canNext={!formDevisMyInformationsInvalid({ situationState })}
        canPrev={true}
        nextStep={handleNextStep}
        prevStep={() => { setSituationStep(0) }}
      />
    </div>
  )
}

export default MySituation;