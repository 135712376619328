const MeAndMySpouse = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M12.68 3.96C13.16 4.67 13.44 5.52 13.44 6.44C13.43 8.84 11.54 10.79 9.16 10.87C9.06 10.86 8.94 10.86 8.83 10.87C6.45 10.79 4.56 8.84 4.56 6.44C4.56 3.99 6.54 2 9 2" stroke="url(#paint0_linear_3101_13361)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M16.41 4C18.35 4 19.91 5.57 19.91 7.5C19.91 9.39 18.41 10.93 16.54 11C16.46 10.99 16.37 10.99 16.28 11" stroke="url(#paint1_linear_3101_13361)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.16 14.56C1.74 16.18 1.74 18.82 4.16 20.43C6.91 22.27 11.42 22.27 14.17 20.43C16.59 18.81 16.59 16.17 14.17 14.56C11.43 12.73 6.92 12.73 4.16 14.56Z" stroke="url(#paint2_linear_3101_13361)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M18.34 20C19.06 19.85 19.74 19.56 20.3 19.13C21.86 17.96 21.86 16.03 20.3 14.86C19.75 14.44 19.08 14.16 18.37 14" stroke="url(#paint3_linear_3101_13361)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <defs>
      <linearGradient id="paint0_linear_3101_13361" x1="4.56" y1="10.87" x2="13.9708" y2="2.6044" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00642E" />
        <stop offset="1" stopColor="#9FC500" />
      </linearGradient>
      <linearGradient id="paint1_linear_3101_13361" x1="16.28" y1="11" x2="21.926" y2="8.43133" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00642E" />
        <stop offset="1" stopColor="#9FC500" />
      </linearGradient>
      <linearGradient id="paint2_linear_3101_13361" x1="2.345" y1="21.81" x2="11.0962" y2="9.66485" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00642E" />
        <stop offset="1" stopColor="#9FC500" />
      </linearGradient>
      <linearGradient id="paint3_linear_3101_13361" x1="18.34" y1="20" x2="23.1984" y2="17.7765" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00642E" />
        <stop offset="1" stopColor="#9FC500" />
      </linearGradient>
    </defs>
  </svg>
)

export default MeAndMySpouse;