const Spouse = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="80" height="79" viewBox="0 0 80 79" fill="none">
    <path d="M28.6397 33.48C28.2397 33.44 27.7597 33.44 27.3197 33.48C17.7997 33.16 10.2397 25.36 10.2397 15.76C10.2397 5.96 18.1597 -2 27.9997 -2C37.7997 -2 45.7597 5.96 45.7597 15.76C45.7197 25.36 38.1597 33.16 28.6397 33.48Z" stroke="url(#paint0_linear_3180_11032)" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M57.6401 6C65.4001 6 71.6401 12.28 71.6401 20C71.6401 27.56 65.6401 33.72 58.1601 34C57.8401 33.96 57.4801 33.96 57.1201 34" stroke="url(#paint1_linear_3180_11032)" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.63988 48.24C-1.04012 54.72 -1.04012 65.28 8.63988 71.72C19.6399 79.08 37.6799 79.08 48.6799 71.72C58.3599 65.24 58.3599 54.68 48.6799 48.24C37.7199 40.92 19.6799 40.92 8.63988 48.24Z" stroke="url(#paint2_linear_3180_11032)" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M65.3599 70C68.2399 69.4 70.9599 68.24 73.1999 66.52C79.4399 61.84 79.4399 54.12 73.1999 49.44C70.9999 47.76 68.3199 46.64 65.4799 46" stroke="url(#paint3_linear_3180_11032)" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    <defs>
      <linearGradient id="paint0_linear_3180_11032" x1="10.2397" y1="33.48" x2="47.8831" y2="0.41761" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00642E" />
        <stop offset="1" stopColor="#9FC500" />
      </linearGradient>
      <linearGradient id="paint1_linear_3180_11032" x1="57.1201" y1="34" x2="79.7043" y2="23.7253" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00642E" />
        <stop offset="1" stopColor="#9FC500" />
      </linearGradient>
      <linearGradient id="paint2_linear_3180_11032" x1="1.37988" y1="77.24" x2="36.3845" y2="28.6594" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00642E" />
        <stop offset="1" stopColor="#9FC500" />
      </linearGradient>
      <linearGradient id="paint3_linear_3180_11032" x1="65.3599" y1="70" x2="84.7933" y2="61.1059" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00642E" />
        <stop offset="1" stopColor="#9FC500" />
      </linearGradient>
    </defs>
  </svg>
)

export default Spouse;