import { useLocation } from "react-router-dom";
import { PersonalInformations, SituationState } from "src/types/Situation";

interface DecodedParams {
  offer: number;
  step: number;
  situationState: SituationState;
  personalInformations: PersonalInformations;
}

function useDecodeUrl(): DecodedParams {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const base64params = params.get('b');
  const decoded = atob(base64params || '');
  let json: DecodedParams;
  try {
    json = JSON.parse(decoded);
  } catch (e) {
    json = {
      offer: 0,
      step: 0,
      situationState: {
        accessionDate: '',
        birthday: '',
        athletic: 'false',
        currentState: '',
        activity: '',
        children: [],
        situation: '',
        outside: '',
        postalCode: '',
        socialSecurityScheme: '',
        spouse: {
          birthday: '',
          athletic: 'false',
          activity: '',
          socialSecurityScheme: ''
        }
      },
      personalInformations: {
        civility: '',
        firstname: '',
        lastname: '',
        streetNumber: '',
        streetName: '',
        country: '',
        city: '',
        email: '',
        phone: '',
        streetMore: '',
        phoneAgreement: false,
        moreGuarantee: false,
        guaranteesSelected: [],
        phonePrefix: '',
      }
    }
  }
  return json;
}

export default useDecodeUrl;