import { BankDetailsInformations } from "src/types/Situation";
import Bank from "src/assets/Bank";

import styles from './styles.module.css';
import Select from "src/components/Ui/Select";
import Input from "src/components/Ui/Input";

interface BankDetailsFormProps {
  bankDetailsInformations: BankDetailsInformations;
  setBankDetailsInformations: (value: any) => void;
}

const BankDetailsForm = ({
  bankDetailsInformations,
  setBankDetailsInformations,
}: BankDetailsFormProps) => {

  const periodicityValues = [
    { v: 'mensuel', l: 'Mensuellement - Le 15 de chaque mois' },
    { v: 'trimestriel', l: 'Trimestriellement - Le 15 de chaque 1er mois' },
    { v: 'semestriel', l: 'Semestriellement - Le 15 de chaque 1er mois' },
    { v: 'annuel', l: 'Annuellement - Le 15 du 1er mois de l\'année' }
  ]

  return (
    <div className={styles.section}>
      <span className={styles.iconInfo}>
        <Bank />
      </span>
      <h1 className={styles.titleSection}>Prélèvement des cotisations</h1>
      <Input
        label="Titulaire du RIB *"
        value={bankDetailsInformations.ownerRib}
        onChange={(ownerRib) =>
          setBankDetailsInformations((prevState: BankDetailsInformations) => ({
            ...prevState,
            ownerRib
          }))
        }
        id="ownerRib"
      />
      <Input
        label="RIB *"
        value={bankDetailsInformations.rib}
        onChange={(rib) =>
          setBankDetailsInformations((prevState: BankDetailsInformations) => ({
            ...prevState,
            rib
          }))
        }
        id="rib"
        maxLength={23}
      />
      <Input
        label="IBAN *"
        value={bankDetailsInformations.ibanFee}
        onChange={(ibanFee) =>
          setBankDetailsInformations((prevState: BankDetailsInformations) => ({
            ...prevState,
            ibanFee
          }))
        }
        id="ibanFee"
        maxLength={34}
      />
      <Input
        label="BIC *"
        value={bankDetailsInformations.bicFee}
        onChange={(bicFee) =>
          setBankDetailsInformations((prevState: BankDetailsInformations) => ({
            ...prevState,
            bicFee
          }))
        }
        id="bicFee"
        maxLength={11}
      />
      <Select
        label="Périodicité *"
        id="dateFee"
        value={bankDetailsInformations.dateFee}
        onChange={(dateFee) =>
          setBankDetailsInformations((prevState: BankDetailsInformations) => ({
            ...prevState,
            dateFee
          }))
        }
        values={periodicityValues}
      />
      <label htmlFor="isOwner" className={`flex items-center ${styles.label}`}>
        Êtes-vous le titulaire du compte de prélèvement ?
      </label>
      <div className="flex flex-row gap-4">
        <button
          className={`${styles.button} ${!bankDetailsInformations.isOwner ? styles.activ : ''}`}
          onClick={() => {
            setBankDetailsInformations((prevState: BankDetailsInformations) => ({
              ...prevState,
              isOwner: false
            }))
          }
          }>Non</button>
        <button
          className={`${styles.button} ${bankDetailsInformations.isOwner ? styles.activ : ''}`}
          onClick={() => {
            setBankDetailsInformations((prevState: BankDetailsInformations) => ({
              ...prevState,
              isOwner: true
            }))
          }
          }>Oui</button>
      </div>
      {!bankDetailsInformations.isOwner && (
        <>
          <Input
            label="Prénom *"
            value={bankDetailsInformations.additionalAccountFirstName}
            onChange={(additionalAccountFirstName) =>
              setBankDetailsInformations((prevState: BankDetailsInformations) => ({
                ...prevState,
                additionalAccountFirstName
              }))
            }
            id="ownerName"
          />
          <Input
            label="Nom *"
            value={bankDetailsInformations.additionalAccountLastName}
            onChange={(additionalAccountLastName) =>
              setBankDetailsInformations((prevState: BankDetailsInformations) => ({
                ...prevState,
                additionalAccountLastName
              }))
            }
            id="ownerLastName"
          />
          <Input
            label="Adresse *"
            value={bankDetailsInformations.additionalAccountAdress}
            onChange={(additionalAccountAdress) =>
              setBankDetailsInformations((prevState: BankDetailsInformations) => ({
                ...prevState,
                additionalAccountAdress
              }))
            }
            id="ownerAdress"
          />
          <div className={styles.row}>
            <div className={styles.column}>
              <Input
                label="Code postal *"
                value={bankDetailsInformations.additionalAccountPostalCode}
                onChange={(additionalAccountPostalCode) =>
                  setBankDetailsInformations((prevState: BankDetailsInformations) => ({
                    ...prevState,
                    additionalAccountPostalCode
                  }))
                }
                id="ownerZip"
              />
            </div>
            <div className={styles.column}>
              <Input
                label="Ville *"
                value={bankDetailsInformations.additionalAccountCity}
                onChange={(additionalAccountCity) =>
                  setBankDetailsInformations((prevState: BankDetailsInformations) => ({
                    ...prevState,
                    additionalAccountCity
                  }))
                }
                id="ownerCity"
              />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default BankDetailsForm;