import React, { useEffect } from 'react';

import useDevis from 'src/hooks/useDevis';
import Dropdown from 'src/assets/Dropdown';
import Arrow from 'src/assets/Offers/Arrow';
import { categories } from 'src/data/DevisData';
import DevisCard from '../Card';

import styles from './styles.module.css';
import OffersModal from 'src/components/Ui/Modals/OffersModal';
import NextButton from 'src/components/Ui/NextButton';
import Spinner from 'src/assets/Spinner';
import Info from 'src/assets/Info';

const Offers = () => {
  const context = useDevis();

  const { setStep, setOffer, offers, situationState } = context;

  const devisRef = React.useRef<HTMLDivElement>(null);
  const [dropdownSelected, setDropdownSelected] = React.useState<string | null>(null);
  const [isMobile, setIsMobile] = React.useState(false);
  const [modal, setModal] = React.useState(false);
  const [dropdownHeight, setDropdownHeight] = React.useState(0);

  React.useLayoutEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize)
  }, []);

  useEffect(() => {
    // get height of the dropdown open
    const dropdown = document.querySelector(`.${styles.dropdownOpen}`);
    let height = 0;
    if (dropdown) {
      height = dropdown.clientHeight;
    }
    setDropdownHeight(height);
  }, [dropdownSelected])

  useEffect(() => {
    if (!isMobile || !devisRef.current) return;

    const indexPromoted = offers.findIndex((offer) => offer.promoted);

    devisRef.current.scrollTo({
      left: 325 * indexPromoted,
      behavior: 'smooth'
    })
  }, [isMobile, offers])

  if (offers.length === 0) {
    if ('LX' === situationState.socialSecurityScheme) {
      return (
        <div className={`flex flex-col flex-wrap content-center p-4 rounded-md items-start ${styles.responsivContainer}`}>
          <div className={`flex flex-col items-start space-y-4 p-14 relative overflow-hidden ${styles.section}`}>
            <span className={styles.iconInfo}>
              <Info />
            </span>
            <h1 className="text-xl font-bold text-blue-900">
              <div className='mb-4'>Votre situation mérite une étude personnalisée pour vous offrir notre meilleure solution santé.</div>
              <div>Nous vous invitons à contacter directement l'un de nos conseillers pour un accompagnement personnalisé.</div>
            </h1>
          </div>
          <NextButton
            canPrev={true}
            canNext={false}
            nextStep={() => setStep(3)}
            prevStep={() => setStep(1)}
          />
        </div>
      )
    }

    return (
      <div className='flex justify-center'>
        <Spinner size={96} />
      </div>
    )
  }

  return (
    <div className="flex flex-col">
      <div className={"flex flex-row gap-12 items-start relative"}>
        {!isMobile && (
          <div className={`flex flex-col justify-around ${styles.leftContainer}`}>
            {categories.map(({ label, icon, key, contents }) => (
              <div key={key} className={`flex items-start flex-col justify-center ${styles.dropdownContainer} ${dropdownSelected === key ? styles.dropdownContainerSelected : ''}`}>
                <span
                  onClick={() => setDropdownSelected(key === dropdownSelected ? null : key)}
                  className="cursor-pointer mr-2 flex flex-row">
                  {contents && <span
                    className={`${styles.dropdown} ${dropdownSelected === key ? styles.dropdownSelected : ''}`}
                  >
                    <Dropdown />
                  </span>
                  }
                  {icon}
                  <span className={`${styles.categoriesLabel} flex flex-col gap-1`}>
                    {label}
                  </span>
                </span>
                {contents && dropdownSelected === key && <span className={`flex flex-col gap-2 mt-4 mb-4 ${dropdownSelected === key ? styles.dropdownOpen : styles.dropdownClose}`}>
                  {contents.map((content, index) => {
                    if (typeof content === 'string') {
                      return <span key={'content' + key + (index + 1)} className={styles.contentTitle}>{content}</span>
                    }
                    return (
                      <ul key={'content' + key + (index + 1)} className="flex flex-col ml-4 gap-2">
                        {content.map((subContent) => (
                          <li key={subContent} className={styles.contentSubContent}>{subContent}</li>
                        ))}
                      </ul>
                    )
                  })}
                </span>}
              </div>
            ))}
          </div>
        )}
        <div
          ref={devisRef}
          className={`flex flex-row gap-6 overflow-x-scroll ${styles.devisContainer}`}>
          {offers.map((devis) => (
            <DevisCard
              key={devis.id}
              devis={devis}
              setOffer={(offer) => {
                setOffer(offer)
                setStep(3)
              }}
              dropdownHeight={{
                height: dropdownHeight,
                key: dropdownSelected
              }}
            />
          ))}
        </div>
        <div
          onClick={() => {
            if (devisRef.current) {
              devisRef.current.scrollTo({
                left: devisRef.current.scrollLeft - 325,
                behavior: 'smooth'
              })
            }
          }}
          className={styles.button + ' ' + styles.leftButton}>
          <span className={styles.innerCircle}>
            <Arrow />
          </span>
        </div>
        <div
          onClick={() => {
            if (devisRef.current) {
              devisRef.current.scrollTo({
                left: devisRef.current.scrollLeft + 325,
                behavior: 'smooth'
              })
            }
          }}
          className={styles.button + ' ' + styles.rightButton}>
          <span className={styles.innerCircle}>
            <Arrow />
          </span>
        </div>
      </div>
      <div className={styles.bottomInformations}>
        <div className={`flex items-end flex-col gap-1 mt-8 mr-16 ${styles.asteriskContainer}`}>
          <span className={`${styles.asterisk} m-auto md:mr-0`}>*Ce tarif inclus les taxes {'true' === situationState.athletic && ('et -10% pour les sportifs')}</span>
          <span className={`${styles.asterisk} m-auto md:mr-0`}>
            ** Voir les{' '}
            <span
              className='underline cursor-pointer'
              onClick={() => {
                setModal(true)
              }}
            >conditions</span>
          </span>
        </div>
      </div>
      {
        modal && (
          <OffersModal
            close={() => setModal(false)}
            isAthletic={situationState.athletic}
          />
        )
      }
      <NextButton
        canPrev={true}
        canNext={false}
        nextStep={() => setStep(3)}
        prevStep={() => setStep(1)}
      />
    </div>
  )
}

export default Offers;