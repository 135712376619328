import { useState } from 'react';

import useSubscription from 'src/hooks/useSubscription';
import { devisData } from "src/data/DevisData";
import DevisResume from "src/components/Devis/Resume";
import NextButton from "src/components/Ui/NextButton";
import MyDocuments from "./MyDocuments";
import SpouseDocument from "./SpouseDocuments";
import OffersModal from 'src/components/Ui/Modals/OffersModal';
import { formDocumentsInformationsInvalid } from 'src/services/validatorsSubscription';

import styles from './styles.module.css';

const SupportingDocuments = () => {
  const [modal, setModal] = useState(false);
  const context = useSubscription();

  const { setStep, offer, documentsInformations, setDocumentsInformations } = context;

  const devis = devisData.find((devis) => devis.id === offer);

  return (
    <div className={`flex justify-center p-4 gap-12 rounded-md ${styles.responsivContainer}`}>
      <div className={`flex flex-col items-start space-y-4 relative overflow-hidden gap-12 ${styles.section}`}>
        <MyDocuments
          documentsInformations={documentsInformations}
          setDocumentsInformations={setDocumentsInformations}
        />
        <SpouseDocument
          documentsInformations={documentsInformations}
          setDocumentsInformations={setDocumentsInformations}
        />
      </div>
      {
        devis &&
        <div className="flex flex-col">
          <DevisResume devis={devis} withoutSubscribeButton actionConditions={() => setModal(true)} />
        </div>
      }

      <NextButton
        canPrev={true}
        canNext={!formDocumentsInformationsInvalid({ documentsInformations })}
        nextStep={() => {
          const stepToGo = 4;
          setStep(stepToGo);
        }}
        prevStep={() => setStep(2)}
      />
      {
        modal && (
          <OffersModal
            close={() => setModal(false)}
            isAthletic={'true'}
          />
        )
      }
    </div >
  );
}

export default SupportingDocuments;