import { DocumentsInformations } from 'src/types/Situation';
import Identity from 'src/assets/Identitity';
import PickerDocument from 'src/components/Ui/PickerDocument';

import styles from './styles.module.css';

interface SpouseDocumentProps {
  documentsInformations: DocumentsInformations;
  setDocumentsInformations: (value: any) => void;
}

const SpouseDocument = ({
  documentsInformations,
  setDocumentsInformations,
}: SpouseDocumentProps) => (
  <div className={styles.section}>
    <span className={styles.iconInfo}>
      <Identity />
    </span>
    <h1 className={styles.titleSection}>Votre conjoint</h1>
    <label className={`mb-0 ${styles.label}`}>
      Justificatif d’identité en cours de validité
    </label>
    <label className={styles.label}>
      (carte d'identité, passeport) *
    </label>
    <PickerDocument
      document={documentsInformations.identifySpouseDocument}
      setDocument={(document: File) => {
        setDocumentsInformations((prev: DocumentsInformations) => ({
          ...prev,
          identifySpouseDocument: document,
        }))
      }}
    />
  </div>
)

export default SpouseDocument;