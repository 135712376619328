import { useState } from 'react';

import useDevis from 'src/hooks/useDevis';
import Recieve from "src/assets/Devis/Recieve";
import ConfirmationBell from "src/assets/Devis/Confirmation/Bell";
import ConfirmationGift from "src/assets/Devis/Confirmation/Gift";
import ConfirmationDocuments from "src/assets/Devis/Confirmation/Documents";
import Download from "src/assets/Needed/Download";
import OffersModal from 'src/components/Ui/Modals/OffersModal';

import styles from './styles.module.css';
import { API_BASE_URL } from 'src/services/api';

const Confirmation = () => {
  const [modal, setModal] = useState(false);
  const context = useDevis();

  const { offer, personalInformations, situationState, offers, quoteNumber } = context;

  const devis = offers.find((devis) => devis.id === offer);

  function redirect() {
    const base64params = btoa(JSON.stringify({ situationState, personalInformations, offer }));
    window.location.href = `/subscription?b=${base64params}`;
  }

  return (
    <div className={`p-4 ${styles.container}`}>
      <div className={styles.card}>
        <div className={styles.header}>
          <span className={styles.recieveIcon}>
            <Recieve />
          </span>
          <span className={styles.title}>
            Vous recevrez votre devis par e-mail dans quelques secondes.
          </span>
        </div>
        <p className={styles.text}>
          Pour toute question ou demande complémentaire,
          n’hésitez pas à <span className="underline cursor-pointer">contacter nos conseillers.</span>
        </p>
        <div className={styles.separator} />
        <span className={styles.title}>
          Ma garantie
        </span>
        {devis && (
          <div className={styles.devisContainer}>
            <span className={styles.devisTitle}>
              {devis.name}
            </span>
            <span className={styles.devisPrice}>
              {devis.price}€
              <span className={styles.devisPriceInfo}>
                /mois
              </span>
            </span>
            <p className={styles.promotion}>
              Ce tarif inclus les taxes {'true' === situationState.athletic && ('et les moins 10% pour les sportifs')}
            </p>
            <button className={styles.button} onClick={() => {
              redirect();
            }}>
              Poursuivre ma souscription en ligne
            </button>
          </div>
        )}
        <div className={styles.catergories}>
          <div className={styles.catergoriesTitle}>
            <span className="flex flex-row flex-1 gap-4 items-center">
              <span className={styles.leftIcon}>
                <ConfirmationBell />
              </span>
              <span className="flex flex-1">
                Services inclus
              </span>
            </span>
          </div>
          <ul className={styles.list}>
            {devis && (
              devis.includedServices.map((service, index) => (
                <li key={index} className={styles.listItem}>
                  {service}
                </li>
              ))
            )}
          </ul>
        </div>
        <div className={styles.separator} />
        <div className={styles.catergories}>
          <div className={styles.catergoriesTitle}>
            <span className="flex flex-row flex-1 gap-4 items-center">
              <span className={styles.leftIcon}>
                <ConfirmationGift />
              </span>
              <span className="flex flex-1">
                Avantages *
              </span>
            </span>
          </div>
          <ul className={styles.list}>
            {devis && (
              devis.includedBenetifs.map((advantage, index) => (
                <li key={index} className={styles.listItem}>
                  {advantage}
                </li>
              ))
            )}
          </ul>
        </div>
        <div className={styles.separator} />
        <div className={styles.catergories}>
          <div className={styles.catergoriesTitle}>
            <span className="flex flex-row flex-1 gap-4 items-center">
              <span className={styles.leftIcon}>
                <ConfirmationDocuments />
              </span>
              <span className="flex flex-1">
                Documents à télécharger
              </span>
            </span>
          </div>
          <ul className={styles.listDocuments}>
            {devis && (
              devis.documents.map((document, index) => (
                <li key={index} className={`cursor-pointer ${styles.listItemDocument}`}>
                  {document.title}
                  <Download />
                </li>
              ))
            )}
          </ul>
        </div>
      </div>
      <div className={`${styles.conditions}`} onClick={() => setModal(true)}>
        * Voir les <span className='underline'>conditions</span>
      </div>
      {
        modal && (
          <OffersModal
            close={() => setModal(false)}
            isAthletic={situationState.athletic}
          />
        )
      }
    </div >
  )
}

export default Confirmation;