import React from "react";

import useDevis from 'src/hooks/useDevis';
import Children from "./Steps/Children";
import Family from "./Steps/Family";
import MySituation from "./Steps/MySituation";
import SpouseSituation from "./Steps/Spouse";

const Situation = () => {
  const context = useDevis();

  const { situationState: { currentState }, situationStep, } = context;

  return (
    <>
      {situationStep === 0 &&
        <Family />
      }
      {situationStep === 1 &&
        <MySituation />
      }
      {situationStep === 2 &&
        (currentState === 'meAndMySpouse' || currentState === 'allMyFamily') &&
        <SpouseSituation />
      }
      {((situationStep === 2 && currentState === 'meAndMyChildren') || situationStep === 3) &&
        <Children />
      }
    </>
  );
}

export default Situation;