import { useEffect, useState } from "react";
import { getQuotePriceYear } from "./api";
import LocalStorageUtils from "./localStorageUtils";

export const useQuotePriceYearFetcher = (currentState?: string): number => {
    const [quotePriceYear, setQuotePriceYear] = useState<number>(new Date().getFullYear());

    useEffect(() => {
        const storage = LocalStorageUtils.getInstance<number>();
        const storedYear = storage.getItem('quote-price-year');
        if (storedYear) {
            setQuotePriceYear(storedYear);
            return;
        }

        getQuotePriceYear().then(data => {
            setQuotePriceYear(data);
            storage.setItem('quote-price-year', data);
        })
    }, [currentState]);

    return quotePriceYear;
};