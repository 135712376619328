import React from 'react';

import Upload from 'src/assets/Upload';

import styles from './styles.module.css';

interface PickerDocumentProps {
  document: File | null;
  setDocument: (document: File) => void;
}

const PickerDocument = ({
  document,
  setDocument,
}: PickerDocumentProps) => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  return (
    <div className={styles.pickerDocument}>
      <button
        className={styles.button}
        onClick={() => {
          inputRef.current?.click();
        }}
      >
        {document ?
          <>
            <span className={styles.title}>
              {document.name}
            </span>
            <span className={styles.subtitle}>
              Choisir un nouveau fichier
            </span>
          </>
          :
          <>
            <span className={styles.title}>
              <Upload />
              Aucun fichier choisi
            </span>
            <span className={styles.subtitle}>
              PDF, JPG, PNG ou TIFF - 5mo maximum
            </span>
          </>
        }
      </button>
      <input
        ref={inputRef}
        style={{ display: 'none' }}
        type="file"
        accept="image/*, application/pdf"
        onChange={(e) => {
          const file = e.target.files?.[0];
          if (file) {
            if (file.size < 5000000) {
              setDocument(file);
            } else {
              alert('Fichier trop lourd');
            }
          }
        }}
      />
    </div>
  )
}

export default PickerDocument;