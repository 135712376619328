const ConfirmationGift = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M4.04948 10.084H19.3828V17.7507C19.3828 20.6257 18.4245 21.584 15.5495 21.584H7.88281C5.00781 21.584 4.04948 20.6257 4.04948 17.7507V10.084Z" stroke="#243E69" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M2.58268 7.20768V8.16602C2.58268 9.22018 3.0906 10.0827 4.49935 10.0827H18.8743C20.3406 10.0827 20.791 9.22018 20.791 8.16602V7.20768C20.791 6.15352 20.3406 5.29102 18.8743 5.29102H4.49935C3.0906 5.29102 2.58268 6.15352 2.58268 7.20768Z" stroke="#243E69" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12.0332 5.29253H17.3232C17.649 4.93794 17.6394 4.39169 17.2944 4.04669L15.9336 2.68586C15.579 2.33128 14.9944 2.33128 14.6398 2.68586L12.0332 5.29253Z" stroke="#243E69" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.06156 5.29253H11.3516L8.7449 2.68586C8.39031 2.33128 7.80573 2.33128 7.45115 2.68586L6.09031 4.04669C5.74531 4.39169 5.73573 4.93794 6.06156 5.29253Z" stroke="#243E69" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.6191 10.084V15.0098C14.6191 15.7765 13.7758 16.2269 13.1337 15.8148L12.2329 15.2207C11.9071 15.0098 11.495 15.0098 11.1787 15.2207L10.3258 15.7957C9.69331 16.2173 8.84039 15.7669 8.84039 15.0002V10.084H14.6191Z" stroke="#243E69" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export default ConfirmationGift;