const Wallet = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="64" height="84" viewBox="0 0 64 84" fill="none">
    <path d="M70.875 66.45H71.7V65.625V28.875V28.05H70.875L18.375 28.05L18.3738 28.05C17.5738 28.0512 16.7796 27.9152 16.0256 27.6479L14.925 27.2577V28.4255V63C14.925 63.915 15.2885 64.7925 15.9355 65.4395C16.5825 66.0865 17.46 66.45 18.375 66.45H70.875ZM18.375 24.45H70.875C72.0486 24.45 73.1741 24.9162 74.0039 25.7461C74.8338 26.5759 75.3 27.7014 75.3 28.875V65.625C75.3 66.7986 74.8338 67.9241 74.0039 68.7539C73.1741 69.5838 72.0486 70.05 70.875 70.05H18.375C16.5052 70.05 14.712 69.3072 13.3899 67.9851C12.0678 66.663 11.325 64.8698 11.325 63V21C11.325 19.1302 12.0678 17.337 13.3899 16.0149C14.712 14.6928 16.5052 13.95 18.375 13.95H63C63.4774 13.95 63.9352 14.1396 64.2728 14.4772C64.6104 14.8148 64.8 15.2726 64.8 15.75C64.8 16.2274 64.6104 16.6852 64.2728 17.0228C63.9352 17.3604 63.4774 17.55 63 17.55H18.375C17.46 17.55 16.5825 17.9135 15.9355 18.5605C15.2885 19.2075 14.925 20.085 14.925 21C14.925 21.915 15.2885 22.7925 15.9355 23.4395C16.5825 24.0865 17.46 24.45 18.375 24.45ZM55.95 45.9375C55.95 45.3219 56.1325 44.7201 56.4746 44.2083C56.8166 43.6964 57.3027 43.2975 57.8714 43.0619C58.4401 42.8263 59.066 42.7647 59.6697 42.8848C60.2735 43.0049 60.8281 43.3013 61.2634 43.7366C61.6987 44.1719 61.9951 44.7265 62.1152 45.3303C62.2353 45.934 62.1737 46.5599 61.9381 47.1286C61.7025 47.6973 61.3036 48.1834 60.7917 48.5254C60.2799 48.8675 59.6781 49.05 59.0625 49.05C58.237 49.05 57.4453 48.7221 56.8616 48.1384C56.2779 47.5547 55.95 46.763 55.95 45.9375Z" fill="url(#paint0_linear_2803_24384)" stroke="#EAF3FF" strokeWidth="1.65" />
    <defs>
      <linearGradient id="paint0_linear_2803_24384" x1="10.5" y1="70.875" x2="72.2876" y2="9.27585" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00642E" />
        <stop offset="1" stopColor="#9FC500" />
      </linearGradient>
    </defs>
  </svg>
)

export default Wallet;