const Hearing = ({ color }: { color?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
    <path d="M10.0501 25.4407C8.51175 25.4407 7.03673 24.8301 5.94923 23.7415C4.86059 22.654 4.25 21.179 4.25 19.6406H6.57C6.57 21.5629 8.12762 23.1206 10.05 23.1206C11.9724 23.1206 13.53 21.5629 13.53 19.6406C13.5266 18.4885 14.178 17.4361 15.2088 16.9229L15.6993 16.6794C16.4436 16.3101 17.0689 15.7403 17.5062 15.0334C17.9423 14.3265 18.1723 13.5109 18.17 12.6805C18.17 10.6086 17.0644 8.69421 15.27 7.65775C13.4757 6.62123 11.2645 6.62123 9.47003 7.65775C7.67565 8.69427 6.57003 10.6088 6.57003 12.6805H4.25003C4.25003 9.77939 5.79744 7.09921 8.31003 5.64805C10.8226 4.19805 13.9175 4.19805 16.43 5.64805C18.9426 7.09918 20.49 9.77939 20.49 12.6805C20.4968 13.9448 20.149 15.1863 19.4852 16.2626C18.8214 17.3388 17.8699 18.2077 16.737 18.7707L16.2465 19.0142H16.2477C16.0075 19.132 15.8546 19.3733 15.85 19.6407C15.85 21.179 15.2394 22.6541 14.1508 23.7416C13.0633 24.8302 11.5886 25.4407 10.0501 25.4407ZM22.213 20.8007C24.098 18.5147 25.1301 15.6442 25.1301 12.6807C25.1301 9.71716 24.0981 6.84674 22.213 4.56067L20.4209 6.04014C21.9649 7.90815 22.81 10.2564 22.81 12.6806C22.81 15.1047 21.9649 17.4531 20.4209 19.321L22.213 20.8007ZM11.5497 13.5008C11.3311 13.2833 11.2087 12.9888 11.2087 12.6806C11.2087 12.3725 11.3311 12.078 11.5497 11.8605C12.0085 11.4221 12.7312 11.4221 13.19 11.8605C13.4075 12.078 13.5299 12.3725 13.5299 12.6806H15.8499C15.8521 11.7585 15.4851 10.8727 14.8315 10.2213C13.4551 8.89818 11.279 8.89818 9.90146 10.2213C9.2467 10.8749 8.87854 11.7619 8.87854 12.6863C8.87854 13.6118 9.2467 14.4988 9.90146 15.1513L11.5497 13.5008Z" fill={color || "url(#paint0_linear_3130_6516)"} />
    <path d="M10.0501 25.4407C8.51175 25.4407 7.03673 24.8301 5.94923 23.7415C4.86059 22.654 4.25 21.179 4.25 19.6406H6.57C6.57 21.5629 8.12762 23.1206 10.05 23.1206C11.9724 23.1206 13.53 21.5629 13.53 19.6406C13.5266 18.4885 14.178 17.4361 15.2088 16.9229L15.6993 16.6794C16.4436 16.3101 17.0689 15.7403 17.5062 15.0334C17.9423 14.3265 18.1723 13.5109 18.17 12.6805C18.17 10.6086 17.0644 8.69421 15.27 7.65775C13.4757 6.62123 11.2645 6.62123 9.47003 7.65775C7.67565 8.69427 6.57003 10.6088 6.57003 12.6805H4.25003C4.25003 9.77939 5.79744 7.09921 8.31003 5.64805C10.8226 4.19805 13.9175 4.19805 16.43 5.64805C18.9426 7.09918 20.49 9.77939 20.49 12.6805C20.4968 13.9448 20.149 15.1863 19.4852 16.2626C18.8214 17.3388 17.8698 18.2077 16.737 18.7707L16.2465 19.0142H16.2477C16.0075 19.132 15.8546 19.3733 15.85 19.6407C15.85 21.179 15.2394 22.6541 14.1508 23.7416C13.0633 24.8302 11.5886 25.4407 10.0501 25.4407ZM22.213 20.8007C24.098 18.5147 25.1301 15.6442 25.1301 12.6807C25.1301 9.71717 24.0981 6.84674 22.213 4.56067L20.4209 6.04014C21.9649 7.90815 22.81 10.2564 22.81 12.6806C22.81 15.1047 21.9649 17.4531 20.4209 19.321L22.213 20.8007ZM11.5497 13.5008C11.3311 13.2833 11.2087 12.9888 11.2087 12.6806C11.2087 12.3725 11.3311 12.078 11.5497 11.8605C12.0085 11.4221 12.7312 11.4221 13.19 11.8605C13.4075 12.078 13.5299 12.3725 13.5299 12.6806H15.8499C15.8521 11.7585 15.4851 10.8727 14.8315 10.2213C13.4551 8.89818 11.279 8.89818 9.90146 10.2213C9.2467 10.8749 8.87854 11.7619 8.87854 12.6863C8.87854 13.6118 9.2467 14.4988 9.90146 15.1513L11.5497 13.5008Z" />
    <defs>
      <linearGradient id="paint0_linear_3130_6516" x1="4.25" y1="25.4408" x2="26.4002" y2="6.00821" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00642E" />
        <stop offset="1" stopColor="#9FC500" />
      </linearGradient>
    </defs>
  </svg>
)

export default Hearing;