import { useState } from 'react';

import useSubscription from 'src/hooks/useSubscription';
import DevisResume from 'src/components/Devis/Resume';
import { devisData } from 'src/data/DevisData';
import NextButton from 'src/components/Ui/NextButton';
import { GuaranteeInformations } from 'src/types/Situation';
import Input from 'src/components/Ui/Input';
import Select from 'src/components/Ui/Select';
import { countries } from 'src/data/Form';
import InfoPicto from 'src/assets/InfoPicto';
import { formGuaranteeInformationsInvalid } from 'src/services/validatorsSubscription';
import OffersModal from 'src/components/Ui/Modals/OffersModal';

import styles from './styles.module.css';

const Guarantee = () => {
  const [modal, setModal] = useState(false);
  const context = useSubscription();

  const { setStep, offer, guaranteeInformations, setGuaranteeInformations, quotePriceYear } = context;

  const devis = devisData.find((devis) => devis.id === offer);

  return (
    <div className={`flex justify-center p-4 gap-12 rounded-md items-start ${styles.responsivContainer}`}>
      <div className={`flex flex-col items-start space-y-4 p-14 relative overflow-hidden ${styles.section}`}>
        <label htmlFor="civility" className={`flex items-center ${styles.label}`}>
          Avez-vous été parrainé ?
        </label>
        <div className="flex flex-row gap-4">
          <button
            className={`${styles.button} ${!guaranteeInformations.hasBeenSponsored ? styles.activ : ''}`}
            onClick={() => {
              setGuaranteeInformations((prevState: GuaranteeInformations) => ({
                ...prevState,
                hasBeenSponsored: false
              }))
            }
            }>Non</button>
          <button
            className={`${styles.button} ${guaranteeInformations.hasBeenSponsored ? styles.activ : ''}`}
            onClick={() => {
              setGuaranteeInformations((prevState: GuaranteeInformations) => ({
                ...prevState,
                hasBeenSponsored: true
              }))
            }
            }>Oui</button>
        </div>
        {guaranteeInformations.hasBeenSponsored && (
          <>
            <Input
              label='Nom du parrain *'
              value={guaranteeInformations.sponsoredName}
              onChange={(sponsoredName) => {
                setGuaranteeInformations((prevState: GuaranteeInformations) => ({
                  ...prevState,
                  sponsoredName: sponsoredName as string
                }))
              }}
              id='sponsoredName'
              type='text'
            />
            <Input
              label='N° adhérent du parrain *'
              value={guaranteeInformations.sponsoredReference}
              onChange={(sponsoredReference) => {
                setGuaranteeInformations((prevState: GuaranteeInformations) => ({
                  ...prevState,
                  sponsoredReference: sponsoredReference as string
                }))
              }}
              id='sponsoredReference'
              type='text'
            />
          </>
        )}
        <label htmlFor="civility" className={`flex items-center ${styles.label}`}>
          Avez-vous une complémentaire santé en cours ?
        </label>
        <div className="flex flex-row gap-4">
          <button
            className={`${styles.button} ${!guaranteeInformations.hasComplementaryHealthInsurance ? styles.activ : ''}`}
            onClick={() => {
              setGuaranteeInformations((prevState: GuaranteeInformations) => ({
                ...prevState,
                hasComplementaryHealthInsurance: false
              }))
            }
            }>Non</button>
          <button
            className={`${styles.button} ${guaranteeInformations.hasComplementaryHealthInsurance ? styles.activ : ''}`}
            onClick={() => {
              setGuaranteeInformations((prevState: GuaranteeInformations) => ({
                ...prevState,
                hasComplementaryHealthInsurance: true
              }))
            }
            }>Oui</button>
        </div>
        {guaranteeInformations.hasComplementaryHealthInsurance && (
          <>
            <label htmlFor='needWipeOut' className={`flex items-center ${styles.label}`}>
              Souhaitiez vous que nous prenions en charge la résiliation de votre complémentaire actuel ?
            </label>
            <div className="flex flex-row gap-4">
              <button
                className={`${styles.button} ${!guaranteeInformations.needWipeOut ? styles.activ : ''}`}
                onClick={() => {
                  setGuaranteeInformations((prevState: GuaranteeInformations) => ({
                    ...prevState,
                    needWipeOut: false,
                    currentHealthInsuranceReference: '',
                    currentHealthInsuranceName: '',
                    adressWipeOut: '',
                    postalCodeWipeOut: '',
                    cityWipeOut: '',
                    countryWipeOut: ''
                  }))
                }
                }>Non</button>
              <button
                className={`${styles.button} ${guaranteeInformations.needWipeOut ? styles.activ : ''}`}
                onClick={() => {
                  setGuaranteeInformations((prevState: GuaranteeInformations) => ({
                    ...prevState,
                    needWipeOut: true
                  }))
                }
                }>Oui</button>
            </div>
            {guaranteeInformations.needWipeOut && (
              <>
                <Input
                  label='Numéro de votre contrat actuel *'
                  value={guaranteeInformations.currentHealthInsuranceReference}
                  onChange={(currentHealthInsuranceReference) => {
                    setGuaranteeInformations((prevState: GuaranteeInformations) => ({
                      ...prevState,
                      currentHealthInsuranceReference: currentHealthInsuranceReference as string
                    }))
                  }}
                  id='currentHealthInsuranceReference'
                  type='text'
                />
                <Input
                  label='Nom de votre assureur ou de votre mutuelle *'
                  value={guaranteeInformations.currentHealthInsuranceName}
                  onChange={(currentHealthInsuranceName) => {
                    setGuaranteeInformations((prevState: GuaranteeInformations) => ({
                      ...prevState,
                      currentHealthInsuranceName: currentHealthInsuranceName as string
                    }))
                  }}
                  id='currentHealthInsuranceName'
                  type='text'
                />
                <Input
                  label='Adresse où doit être envoyée votre résiliation *'
                  value={guaranteeInformations.adressWipeOut}
                  onChange={(adressWipeOut) => {
                    setGuaranteeInformations((prevState: GuaranteeInformations) => ({
                      ...prevState,
                      adressWipeOut: adressWipeOut as string
                    }))
                  }}
                  id='adressWipeOut'
                  type='text'
                />
                <div className='flex flex-row gap-4'>
                  <div className='flex flex-1 flex-col'>
                    <Input
                      label='Code postal *'
                      value={guaranteeInformations.postalCodeWipeOut}
                      onChange={(postalCodeWipeOut) => {
                        setGuaranteeInformations((prevState: GuaranteeInformations) => ({
                          ...prevState,
                          postalCodeWipeOut: postalCodeWipeOut as string
                        }))
                      }}
                      id='postalCodeWipeOut'
                      type='text'
                    />
                  </div>
                  <div className='flex flex-1 flex-col'>
                    <Input
                      label='Ville *'
                      value={guaranteeInformations.cityWipeOut}
                      onChange={(cityWipeOut) => {
                        setGuaranteeInformations((prevState: GuaranteeInformations) => ({
                          ...prevState,
                          cityWipeOut: cityWipeOut as string
                        }))
                      }}
                      id='cityWipeOut'
                      type='text'
                    />
                  </div>
                  <div className='flex flex-1 flex-col'>
                    <Select
                      label='Pays *'
                      value={guaranteeInformations.countryWipeOut}
                      onChange={(countryWipeOut) => {
                        setGuaranteeInformations((prevState: GuaranteeInformations) => ({
                          ...prevState,
                          countryWipeOut
                        }))
                      }}
                      id='countryWipeOut'
                      values={countries.map((country) => ({ v: country.value, l: country.label }))}
                    />
                  </div>
                </div>
                <div className='flex justify-end w-full'>
                  <p style={{ color: '#243E69', fontSize: '12px', fontWeight: 400 }}>(*) : champs obligatoires</p>
                </div>
              </>
            )}
          </>
        )}
        <label htmlFor='startingDate' className={`flex items-center ${styles.label}`}>
          Date de démarrage de votre complémentaire santé *
        </label>
        {guaranteeInformations.hasComplementaryHealthInsurance && (
          <div className={styles.advise}>
            <InfoPicto />
            Pas de démarrage possible avant le 06/06/2024.
          </div>
        )}
        <Input
          value={guaranteeInformations.startingDate}
          onChange={() => {}}
          id='startingDate'
          type='date'
          maxLength={10}
          disabled={guaranteeInformations.startingDate !== '' ? true : false}
          minDate={new Date().toISOString().split('T')[0]}
          maxDate={quotePriceYear + '-12-31'}
        />
      </div>
      {devis &&
        <div className="flex flex-col">
          <DevisResume devis={devis} withoutSubscribeButton actionConditions={() => setModal(true)} />
        </div>
      }
      {
        modal && (
          <OffersModal
            close={() => setModal(false)}
            isAthletic={'true'}
          />
        )
      }
      <NextButton
        canNext={!formGuaranteeInformationsInvalid({ guaranteeInformations })}
        canPrev={false}
        nextStep={() => setStep(1)}
        prevStep={() => { }}
      />
    </div>
  );
}

export default Guarantee;