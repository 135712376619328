import { Outlet } from 'react-router-dom';
import { DevisProvider } from 'src/context/DevisContext';

const DevisLayout = () => {
  return (
    <DevisProvider>
      <Outlet />
    </DevisProvider>
  )
}

export default DevisLayout;