import Cross from "src/assets/Cross";
import Modal from "..";

import styles from './styles.module.css';
import CheckPicto from "src/assets/CheckPicto";

interface OffersModalProps {
  close: () => void;
  isAthletic: string;
}

const OffersModal = ({
  close,
  isAthletic
}: OffersModalProps) => (
  <Modal>
    <div className="flex flex-row justify-end overflow-auto h-full">
      <span onClick={close} className="cursor-pointer">
        <Cross />
      </span>
    </div>
    <h1 className={`mb-4 ${styles.title}`}>
      Conditions
    </h1>
    <div className={styles.card}>
      <span style={{ height: 32, width: 32 }}>
        <CheckPicto />
      </span>
      <span className={styles.content}>
        <h3 className={`mb-4 ${styles.subtitle}`}>Bénéficiez d'un mois  offert sur votre cotisation santé !</h3>
        <p className={styles.text}>Votre cotisation santé est offerte pendant un mois pour toute nouvelle souscription individuelle à une complémentaire santé réalisée depuis notre site internet.</p>
        <p className={styles.text}>Cette offre est non cumulable avec d'autres réductions ou avantages accordés et valable pour un engagement d'une durée minimale de 12 mois.</p>
        <p className={styles.text}>L’avantage sera appliqué à partir du 4e mois de la première année d'adhésion.</p>
      </span>
    </div>
    {'true' === isAthletic && (
    <div className={styles.card}>
      <span style={{ height: 32, width: 32 }}>
        <CheckPicto />
      </span>
      <span className={styles.content}>
        <h3 className={`mb-4 ${styles.subtitle}`}>Bénéficiez de 10% de réduction sur votre cotisation santé !</h3>
        <p className={`mb-4 ${styles.text}`}>L’offre de réduction "10% sportif" concerne les personnes souscrivant à une complémentaire santé à titre individuel.</p>
        <p className={styles.text}>Pour bénéficier de la réduction :</p>
        <ul className={`mb-4 ${styles.list}`}>
          <li className={styles.listItem}>L'adhérent devra justifier de la pratique d’un sport à l’adhésion en fournissant une licence sportive ou une carte de membre de club sportif en cours de validité.</li>
          <li className={styles.listItem}>La réduction s’applique sur la cotisation santé (hors frais d’encaissement éventuels) relative au bénéficiaire adulte justifiant de la pratique d’un sport.</li>
          <li className={styles.listItem}>La réduction sera appliquée jusqu’à la fin de validité du justificatif (1) et pour une durée maximum de 12 mois.</li>
        </ul>
        <p className={`mb-4 ${styles.text}`}>Afin de continuer à profiter de cette offre, le bénéficiaire de la réduction devra justifier de la pratique régulière d’un sport. Pour cela, il devra fournir à chaque renouvellement de licence ou de carte de membre sportif une photocopie de l’un de ces documents précisant la nouvelle validité pour l’année en cours. En cas de justificatif valable plusieurs années consécutives, le bénéficiaire devra fournir annuellement une attestation de son club sportif certifiant qu’il poursuit la pratique d’un sport pour l’année en cours. Les abonnements prévoyant une radiation à tout moment ne sont pas recevables.</p>
        <p className={`mb-4 ${styles.text}`}>Cette possibilité de réduction est non cumulable avec d’autres avantages tarifaires qui pourraient être consentis. De plus, le décret n°2011-1474 du 8 novembre 2011 relatif à la participation des collectivités territoriales et de leurs établissements publics au financement de la protection complémentaire de leurs agents, ne permet pas aux adhérents souscrivant à un contrat labellisé de bénéficier de cette offre.
        </p>
        <p className={styles.asterisk}>(1) seuls les justificatifs précisant une date de fin de validité seront acceptés. ils seront pris en compte à leur date de réception, aucun effet rétroactif ne sera enregistré.</p>
      </span>
    </div>
  )}
  </Modal>
)

export default OffersModal;