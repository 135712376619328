import React from "react";

import useDevis from "src/hooks/useDevis";
import { situationValues } from "src/data/DevisData";
import { SituationState } from "src/types/Situation";
import NextButton from "src/components/Ui/NextButton";
import AdditionalOffers from "src/components/Ui/AdditionalOffers";
import FamilyOfferModal from "src/components/Ui/Modals/FamilyOfferModal";

import styles from './styles.module.css';

const Family = () => {
  const context = useDevis();

  const { setSituationState, situationState: { currentState }, setMaxSteps, setSituationStep } = context;

  const [modal, setModal] = React.useState(false);

  return (
    <div className={`flex justify-center items-start p-4 gap-12 rounded-md ${styles.responsivContainer}`}>
      <div className={`flex flex-col items-start space-y-4 p-14 ${styles.section}`}>
        <h1 className={styles.titleSection}>Je souhaite une complémentaire santé pour :</h1>
        {situationValues.map((situationValue, index) => (
          <button
            key={situationValue.value}
            onClick={() => {
              setSituationState((prevState: SituationState) => ({
                ...prevState,
                currentState: situationValue.value
              }))
              if (situationValue.value === 'me') {
                setMaxSteps(1);
              }
              if (situationValue.value === 'meAndMySpouse') {
                setMaxSteps(2);
              }
              if (situationValue.value === 'meAndMyChildren') {
                setMaxSteps(2);
              }
              if (situationValue.value === 'allMyFamily') {
                setMaxSteps(3);
              }
            }}
            style={{
              width: 30 + ((index + 1) * 15) + '%'
            }}
            className={`flex items-center p-1 gap-4 ${styles.button} ${situationValue.value === currentState ? styles.activ : ''}`}>
            <span className={`${styles.icon} ${situationValue.value === currentState ? styles.iconActiv : ''}`}>
              {situationValue.icon}
            </span>
            {situationValue.label}
          </button>
        ))}
      </div>
      <AdditionalOffers
        image="/images/situation-offer.jpg"
        title="1 Mois offert"
        description="Pour toute souscription en ligne"
        action={() => {
          setModal(true);
        }}
      />
      {modal && (
        <FamilyOfferModal close={() => setModal(false)} />
      )}
      <NextButton
        canNext={currentState !== ''}
        canPrev={false}
        nextStep={() => setSituationStep(1)}
        prevStep={() => { }}
      />
    </div>
  )
}

export default Family;