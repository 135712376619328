import { useEffect, useState } from "react";
import { getSituations } from "./api";
import { Situation } from "src/types/Situation";

export const useSituationsFetcher = (outside: string) => {
    const [situations, setSituations] = useState([] as Situation[]);
    useEffect(() => {
        if (!['0', '1'].includes(outside)) return;

        getSituations(outside).then(data => {
            setSituations(data);
        });
    }, [outside]);

  return situations;
};