const CurrentCare = ({ color }: { color?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M12.2839 20.4428C11.9581 20.5578 11.4214 20.5578 11.0956 20.4428C8.31645 19.494 2.10645 15.5361 2.10645 8.82779C2.10645 5.86654 4.4927 3.4707 7.43478 3.4707C9.17895 3.4707 10.7219 4.31404 11.6898 5.61737C12.6577 4.31404 14.2102 3.4707 15.9448 3.4707C18.8869 3.4707 21.2731 5.86654 21.2731 8.82779C21.2731 15.5361 15.0631 19.494 12.2839 20.4428Z" stroke={color || "url(#paint0_linear_3130_6507)"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <defs>
      <linearGradient id="paint0_linear_3130_6507" x1="2.10645" y1="20.529" x2="20.3556" y2="2.53994" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00642E" />
        <stop offset="1" stopColor="#9FC500" />
      </linearGradient>
    </defs>
  </svg>
)

export default CurrentCare; 