const EmailButton = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
    <path d="M12.5 2V8L14.5 6" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12.5 8L10.5 6" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.5 12C3.5 12 3.5 13.79 3.5 16V17C3.5 19.76 3.5 22 8.5 22H16.5C20.5 22 21.5 19.76 21.5 17V16C21.5 13.79 21.5 12 17.5 12C16.5 12 16.22 12.21 15.7 12.6L14.68 13.68C13.5 14.94 11.5 14.94 10.31 13.68L9.3 12.6C8.78 12.21 8.5 12 7.5 12Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.5 11.9991V7.99906C5.5 5.98906 5.5 4.32906 8.5 4.03906" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M19.5 11.9991V7.99906C19.5 5.98906 19.5 4.32906 16.5 4.03906" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export default EmailButton;