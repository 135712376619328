import { Devis } from 'src/types/Situation';
import styles from './styles.module.css';

interface GuaranteeCardProps {
  guarantee: {
    id: number;
    name: string;
    price: number;
  };
  guaranteesSelected: Devis[];
  onClick: (id: number) => void;
}

const GuaranteeCard = ({ guarantee, guaranteesSelected, onClick }: GuaranteeCardProps) => {
  const selected = guaranteesSelected.filter(g => g.id === guarantee.id).length;
  return (
    <div className={styles.container}>
      <div className={`${styles.banner} ${selected ? styles.bannerSelected : ''}`} />
      <div className={styles.content}>
        <span className={styles.title}>
          {guarantee.name}
        </span>
        <span className={styles.price}>
          {guarantee.price}€
          <span className={styles.month}>/mois</span>
        </span>
        <button
          onClick={() => onClick(guarantee.id)}
          className={`${styles.button} ${selected ? styles.buttonSelected : ''}`}>
          Ajouter
        </button>
      </div>
    </div>
  )
}

export default GuaranteeCard;