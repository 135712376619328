import Cross from "src/assets/Cross";
import Modal from "..";

import styles from './styles.module.css';

interface ChildrenOfferModalProps {
  close: () => void;
}

const ChildrenOfferModal = ({
  close,
}: ChildrenOfferModalProps) => (
  <Modal>
    <div className="flex flex-row justify-end">
      <span onClick={close} className="cursor-pointer">
        <Cross />
      </span>
    </div>
    <h3 className={`mb-4 ${styles.title}`}>Conditions</h3>
    <p className={`mb-4 ${styles.text}`}>Vous ne payez que pour les deux premiers enfants, les suivants sont couverts gratuitement.</p>
    <p className={styles.text}>Sont considérés comme enfants à charge les enfants qui remplissent les conditions prévues par la législation sur les prestations familiales, à l’exception le cas échéant de la condition d’âge.</p>
    <p className={styles.text}>Sont donc assimilés aux enfants à charge :</p>
    <ul className={styles.list}>
      <li className={styles.listItem}>a - les enfants de moins de 21 ans scolarisés</li>
      <li className={styles.listItem}>b - les enfants de moins de 25 ans lorsque :</li>
      <li className={styles.listItem}>
        <ul>
          <li className={styles.subListItem}>ils poursuivent des études supérieures,</li>
          <li className={styles.subListItem}>ils sont inscrits dans une formation en alternance dont la rémunération est inférieure à 55% du SMIC (contrat d’apprentissage, de qualification, …),</li>
          <li className={styles.subListItem}>ils sont à la recherche d’un premier emploi pendant une durée maximum de 1 an et sont inscrits à ce titre à Pôle Emploi,</li>
        </ul>
      </li>
      <li className={styles.listItem}>c - les enfants, quel que soit leur âge, qui présentent une inaptitude au travail les mettant hors d’état de se procurer par une activité quelconque une rémunération supérieure au minimum de pension de vieillesse prévu à l'article L.351.10 du Code de la Sécurité Sociale, augmenté du montant de l’allocation supplémentaire du Fonds de Solidarité Vieillesse et une incapacité permanente de 80 % au moins.</li>
    </ul>
  </Modal>
)

export default ChildrenOfferModal;