import { useEffect, useState } from "react";
import { getCitiesByPostalCode } from "./api";
import { City } from "src/types/Situation";
import LocalStorageUtils from "./localStorageUtils";

export const useCityFetcher = (postalCode: string): City[] => {
    const [cities, setCities] = useState<City[]>([]);
    useEffect(() => {
        if (!postalCode || postalCode.length !== 5) return;

        const cityStorage = LocalStorageUtils.getInstance<City[]>();
        const storedCities = cityStorage.getItem(postalCode);
        if (storedCities) {
            setCities(storedCities);
            return;
        }

        getCitiesByPostalCode(postalCode).then((data) => {
            setCities(data);
            cityStorage.setItem(postalCode, data);
        });
    }, [postalCode, setCities]);

    return cities;
};