const Optic = ({ color }: { color?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M15.1205 11.9992C15.1205 13.8967 13.5871 15.43 11.6896 15.43C9.79212 15.43 8.25879 13.8967 8.25879 11.9992C8.25879 10.1017 9.79212 8.56836 11.6896 8.56836C13.5871 8.56836 15.1205 10.1017 15.1205 11.9992Z" stroke={color || "url(#paint0_linear_3130_6491)"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.6898 19.9249C15.0727 19.9249 18.2256 17.9315 20.4202 14.4815C21.2827 13.1303 21.2827 10.859 20.4202 9.50779C18.2256 6.05779 15.0727 4.06445 11.6898 4.06445C8.30687 4.06445 5.15396 6.05779 2.95937 9.50779C2.09687 10.859 2.09687 13.1303 2.95937 14.4815C5.15396 17.9315 8.30687 19.9249 11.6898 19.9249Z" stroke={color || "url(#paint1_linear_3130_6491)"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <defs>
      <linearGradient id="paint0_linear_3130_6491" x1="8.25879" y1="15.43" x2="15.5378" y2="9.04405" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00642E" />
        <stop offset="1" stopColor="#9FC500" />
      </linearGradient>
      <linearGradient id="paint1_linear_3130_6491" x1="2.3125" y1="19.9249" x2="19.2704" y2="2.33259" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00642E" />
        <stop offset="1" stopColor="#9FC500" />
      </linearGradient>
    </defs>
  </svg>
)

export default Optic;