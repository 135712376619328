import React from "react";

import useDevis from 'src/hooks/useDevis';
import NextButton from "src/components/Ui/NextButton";
import CurrentCare from "src/assets/Needed/CurrentCare";
import Dental from "src/assets/Needed/Dental";
import Hearing from "src/assets/Needed/Hearing";
import Hospitalization from "src/assets/Needed/Hospitalization";
import Optic from "src/assets/Needed/Optic";
import { getProductPricing } from "src/services/api";

import styles from './styles.module.css';
import { Devis } from "src/types/Situation";

const Needed = () => {
  const context = useDevis();

  const { neededState, setNeededState, setStep, situationState, setOffers } = context;

  const handleSelect = (category: string, level: number) => {
    setNeededState({
      ...neededState,
      [category]: level,
    });
  };

  const handleNext = () => {
    setStep(2);
    const totalScore = neededState.currentCare + neededState.dental + neededState.hearing + neededState.hospitalization + neededState.optical;
    getProductPricing(situationState, totalScore).then((offers) => {
      if (!offers.products) {
        setOffers([]);
        return;
      }

      const castedOffers = offers.products.map((offer: any) => {
        return {
          id: offer.id,
          code: offer.code,
          name: offer.name,
          price: offer.price,
          includedBenetifs: offer.includedBenetifs,
          includedServices: offer.includedServices,
          documents: offer.documents.map((doc: any) => {
            return {
              title: doc.title,
              url: doc.url
            }
          }),
          dentalScore: offer.dentalScore,
          dentalDropdown: offer.dentalDropdown.map((dropdown: any) => {
            return {
              label: dropdown.label,
              reduction: dropdown.reduction
            }
          }),
          hospitalizationScore: offer.hospitalizationScore,
          hospitalizationDropdown: offer.hospitalizationDropdown.map((dropdown: any) => {
            return {
              label: dropdown.label,
              reduction: dropdown.reduction
            }
          }),
          standardCareScore: offer.standardCareScore,
          standardCareDropdown: offer.standardCareDropdown.map((dropdown: any) => {
            return {
              label: dropdown.label,
              reduction: dropdown.reduction
            }
          }),
          hearingScore: offer.hearingScore,
          hearingDropdown: offer.hearingDropdown.map((dropdown: any) => {
            return {
              label: dropdown.label,
              reduction: dropdown.reduction
            }
          }),
          opticalScore: offer.opticalScore,
          opticalDropdown: offer.opticalDropdown.map((dropdown: any) => {
            return {
              label: dropdown.label,
              reduction: dropdown.reduction
            }
          }),
          promoted: offer.promoted
        } as Devis;
      });

      setOffers(castedOffers);
    });
  };

  const [isMobile, setIsMobile] = React.useState(false);

  React.useLayoutEffect(() => {
    const updateSize = () => {
      setIsMobile(window.innerWidth < 868);
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const coverageLevels = [
    { name: 'Minimum', value: 1 },
    { name: 'Intermédiaire', value: 2 },
    { name: 'Élevée', value: 3 },
    { name: 'Maximum', value: 4 },
  ];

  const categories = [
    { label: 'Dentaire', icon: <Dental />, key: 'dental' },
    { label: 'Optique', icon: <Optic />, key: 'optical' },
    { label: 'Hospitalisation', icon: <Hospitalization />, key: 'hospitalization' },
    { label: 'Soins courants', icon: <CurrentCare />, key: 'currentCare', subTitle: 'Médecin, kiné, infirmier...' },
    { label: 'Audition', icon: <Hearing />, key: 'hearing' },
  ]

  return (
    <div className="flex flex-col">
      <h1 className={`${styles.titleSection} text-center`}>
        Pour mes dépenses en santé,
      </h1>
      <h1 className={`${styles.titleSection} mb-8 text-center`}>
        je cherche un niveau de couverture :
      </h1>
      {!isMobile && (
        <div className={`flex flex-row gap-4`}>
          <div className={`flex flex-1 flex-col justify-around space-y-4 ${styles.leftContainer}`}>
            {categories.map(({ label, icon, key, subTitle }) => (
              <div key={key} className="flex items-center">
                <span className="mr-2 flex flex-row">
                  {icon}
                  <span className={`${styles.categoriesLabel} flex flex-col gap-1`}>
                    {label}
                    {subTitle && <span className="text-sm">{subTitle}</span>}
                  </span>
                </span>
              </div>
            ))}
          </div>
          <div className={`overflow-x-auto ${styles.centerContainer}`}>
            <div className={`space-y-6 p-12 ${styles.neededBloc}`}>
              {['dental', 'optical', 'hospitalization', 'currentCare', 'hearing'].map(category => (
                <div key={category} className="flex justify-between">
                  {coverageLevels.map(level => (
                    <button
                      key={level.value}
                      className={`${styles.button} ml-2 mr-2 rounded-full ${neededState[category as keyof typeof neededState] === level.value
                        ? styles.activ
                        : styles.inactiv
                        }`}
                      onClick={() => handleSelect(category, level.value)}
                    >
                      {level.name}
                    </button>
                  ))}
                </div>
              ))}
            </div>
          </div>
          <div className={`flex flex-1 p-12 ${styles.hiddenMobile}`} />
        </div>
      )}
      {isMobile && (
        <div className={`flex flex-col gap-4`}>
          {categories.map(({ label, icon, key, subTitle }) => (
            <div key={key} className="flex flex-col gap-4">
              <div className="flex items-center">
                <span className="mr-2 flex flex-row ml-4">
                  {icon}
                  <span className={`${styles.categoriesLabel} flex flex-col gap-1`}>
                    {label}
                    {subTitle && <span className="text-sm">{subTitle}</span>}
                  </span>
                </span>
              </div>
              <div className="flex flex-wrap gap-6 p-4 relative">
                <div className="flex absolute left-7 right-7 justify-evenly pointer-events-none">
                  <div className="h-4 w-1 bg-gray-300" />
                  <div className="h-4 w-1 bg-gray-300" />
                </div>
                <input
                  type="range"
                  min="1"
                  max="4"
                  value={neededState[key as keyof typeof neededState]}
                  onChange={(e) => handleSelect(key, parseInt(e.target.value))}
                  className="w-full z-10"
                />
                <div className="flex justify-between w-full relative">
                  {coverageLevels.map(level => (
                    <span
                      key={level.value}
                      className={`${styles.button} flex absolute`}
                      style={{
                        left: `${(level.value - 1) * 25}%`,
                        top: '-20px',
                        display: neededState[key as keyof typeof neededState] === level.value ? 'block' : 'none',
                      }}
                    >
                      {level.name}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <NextButton
        canPrev={true}
        canNext={
          neededState.dental !== 0 &&
          neededState.optical !== 0 &&
          neededState.hospitalization !== 0 &&
          neededState.currentCare !== 0 &&
          neededState.hearing !== 0
        }
        nextStep={handleNext}
        prevStep={() => setStep(0)}
      />
    </div>
  )
}

export default Needed;