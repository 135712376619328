const Ring = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M2.3457 17.0312H21.0332C21.2238 17.0312 21.4066 16.9555 21.5414 16.8207C21.6762 16.6859 21.752 16.5031 21.752 16.3125C21.752 16.1219 21.6762 15.9391 21.5414 15.8043C21.4066 15.6695 21.2238 15.5938 21.0332 15.5938H20.3145V14.1562C20.3118 11.994 19.4982 9.91139 18.0343 8.32006C16.5703 6.72872 14.5627 5.74446 12.4082 5.5618V4.09375H13.8457C14.0363 4.09375 14.2191 4.01802 14.3539 3.88323C14.4887 3.74844 14.5645 3.56562 14.5645 3.375C14.5645 3.18438 14.4887 3.00156 14.3539 2.86677C14.2191 2.73198 14.0363 2.65625 13.8457 2.65625H9.5332C9.34258 2.65625 9.15976 2.73198 9.02497 2.86677C8.89018 3.00156 8.81445 3.18438 8.81445 3.375C8.81445 3.56562 8.89018 3.74844 9.02497 3.88323C9.15976 4.01802 9.34258 4.09375 9.5332 4.09375H10.9707V5.5618C8.81617 5.74446 6.80856 6.72872 5.34465 8.32006C3.88074 9.91139 3.06707 11.994 3.06445 14.1562V15.5938H2.3457C2.15508 15.5938 1.97226 15.6695 1.83747 15.8043C1.70268 15.9391 1.62695 16.1219 1.62695 16.3125C1.62695 16.5031 1.70268 16.6859 1.83747 16.8207C1.97226 16.9555 2.15508 17.0312 2.3457 17.0312ZM4.50195 14.1562C4.50195 12.25 5.25921 10.4218 6.60712 9.07392C7.95504 7.726 9.78321 6.96875 11.6895 6.96875C13.5957 6.96875 15.4239 7.726 16.7718 9.07392C18.1197 10.4218 18.877 12.25 18.877 14.1562V15.5938H4.50195V14.1562ZM21.752 19.1875C21.752 19.3781 21.6762 19.5609 21.5414 19.6957C21.4066 19.8305 21.2238 19.9062 21.0332 19.9062H2.3457C2.15508 19.9062 1.97226 19.8305 1.83747 19.6957C1.70268 19.5609 1.62695 19.3781 1.62695 19.1875C1.62695 18.9969 1.70268 18.8141 1.83747 18.6793C1.97226 18.5445 2.15508 18.4688 2.3457 18.4688H21.0332C21.2238 18.4688 21.4066 18.5445 21.5414 18.6793C21.6762 18.8141 21.752 18.9969 21.752 19.1875Z" fill="url(#paint0_linear_2644_27738)" />
    <defs>
      <linearGradient id="paint0_linear_2644_27738" x1="1.62695" y1="19.9063" x2="20.0781" y2="1.02087" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00642E" />
        <stop offset="1" stopColor="#9FC500" />
      </linearGradient>
    </defs>
  </svg>
)

export default Ring;