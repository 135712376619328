import React, { createContext, useState, ReactNode } from 'react';

import { situationValues } from '../data/DevisData';
import { NeededState, PersonalInformations, Situation, SituationState, SocialSecurityScheme, Activity, Devis, City } from '../types/Situation';

import { useCityFetcher } from 'src/services/cityUtils';
import { useQuotePriceYearFetcher } from 'src/services/quotePriceYearUtils';
import { useActivitiesFetcher } from 'src/services/activityUtils';
import { useSocialSecuritySchemesFetcher } from 'src/services/socialSecuritySchemeUtils';
import { useSituationsFetcher } from 'src/services/situationUtils';

interface DevisContextProps {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  maxSteps: number;
  setMaxSteps: React.Dispatch<React.SetStateAction<number>>;
  situationStep: number;
  setSituationStep: React.Dispatch<React.SetStateAction<number>>;
  situationState: SituationState;
  setSituationState: React.Dispatch<React.SetStateAction<SituationState>>;
  neededState: NeededState;
  setNeededState: React.Dispatch<React.SetStateAction<NeededState>>;
  offer: number | null;
  setOffer: React.Dispatch<React.SetStateAction<number | null>>;
  personalInformations: PersonalInformations;
  setPersonalInformations: React.Dispatch<React.SetStateAction<PersonalInformations>>;
  situationsDropdownValues: Array<Situation>;
  socialSecuritySchemes: Array<SocialSecurityScheme>;
  activities: Array<Activity>;
  spouseSocialSecuritySchemes: Array<SocialSecurityScheme>;
  spouseActivityValues: Array<Activity>;
  quotePriceYear: number;
  offers: Array<Devis>;
  setOffers: React.Dispatch<React.SetStateAction<Array<Devis>>>;
  cities: Array<City>;
  quoteNumber: string;
  setQuoteNumber: React.Dispatch<React.SetStateAction<string>>;
}

const DevisContext = createContext<DevisContextProps | undefined>(undefined);

const DevisProvider = ({ children }: { children: ReactNode }) => {
  const [step, setStep] = useState(0);

  const [maxSteps, setMaxSteps] = React.useState(1);
  const [situationStep, setSituationStep] = React.useState(0);

  // STEP 1 - Situation
  const [situationState, setSituationState] = useState<SituationState>({
    currentState: situationValues[0].value,
    outside: '0',
    postalCode: '',
    birthday: '',
    accessionDate: '',
    situation: '',
    socialSecurityScheme: '',
    activity: '',
    athletic: 'false',
    children: [],
    spouse: {
      activity: '',
      athletic: 'false',
      birthday: '',
      socialSecurityScheme: ''
    }
  });

  const situationsDropdownValues = useSituationsFetcher(situationState.outside);

  const allSocialSecuritySchemes = useSocialSecuritySchemesFetcher(situationState.situation, situationState.outside);
  const socialSecuritySchemes = allSocialSecuritySchemes.a;
  const spouseSocialSecuritySchemes = allSocialSecuritySchemes.c;

  const activities = useActivitiesFetcher(situationState.situation, situationState.socialSecurityScheme, situationState.outside);
  const spouseActivityValues = useActivitiesFetcher(situationState.situation, situationState.spouse.socialSecurityScheme, situationState.outside, 'c');

  const quotePriceYear = useQuotePriceYearFetcher(situationState.currentState);

  const cities = useCityFetcher(situationState.postalCode);

  // STEP 2 - Besoins
  const [neededState, setNeededState] = useState({
    dental: 1,
    optical: 1,
    hospitalization: 1,
    currentCare: 1,
    hearing: 1,
  });

  const [offers, setOffers] = useState<Devis[]>([]);

  // STEP 3 - Offres
  const [offer, setOffer] = useState<null | number>(null);

  // STEP 4 - Informations personnelles
  const [personalInformations, setPersonalInformations] = useState<PersonalInformations>({
    civility: '1',
    firstname: '',
    lastname: '',
    email: '',
    streetNumber: '',
    streetName: '',
    streetMore: '',
    country: 'FR',
    city: '',
    phonePrefix: '',
    phone: '',
    moreGuarantee: false,
    guaranteesSelected: [],
    phoneAgreement: false,
  });

  const [quoteNumber, setQuoteNumber] = useState('');

  return (
    <DevisContext.Provider value={{
      step,
      setStep,
      maxSteps,
      setMaxSteps,
      situationStep,
      setSituationStep,
      situationState,
      setSituationState,
      neededState,
      setNeededState,
      offer,
      setOffer,
      personalInformations,
      setPersonalInformations,
      situationsDropdownValues,
      socialSecuritySchemes,
      activities,
      spouseSocialSecuritySchemes,
      spouseActivityValues,
      quotePriceYear,
      offers,
      setOffers,
      cities,
      quoteNumber,
      setQuoteNumber
    }}>
      {children}
    </DevisContext.Provider>
  );
};

export { DevisProvider, DevisContext };
