const Calendar = ({ color }: { color?: string }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
        <path d="M8.5 5.75C8.09 5.75 7.75 5.41 7.75 5V2C7.75 1.59 8.09 1.25 8.5 1.25C8.91 1.25 9.25 1.59 9.25 2V5C9.25 5.41 8.91 5.75 8.5 5.75Z" fill={color || '#243E69'} />
        <path d="M16.5 5.75C16.09 5.75 15.75 5.41 15.75 5V2C15.75 1.59 16.09 1.25 16.5 1.25C16.91 1.25 17.25 1.59 17.25 2V5C17.25 5.41 16.91 5.75 16.5 5.75Z" fill={color || '#243E69'} />
        <path d="M9 14.4984C8.87 14.4984 8.74 14.4684 8.62 14.4184C8.49 14.3684 8.39 14.2983 8.29 14.2083C8.11 14.0183 8 13.7684 8 13.4984C8 13.3684 8.03 13.2384 8.08 13.1184C8.13 12.9984 8.2 12.8884 8.29 12.7884C8.39 12.6984 8.49 12.6283 8.62 12.5783C8.98 12.4283 9.43 12.5084 9.71 12.7884C9.89 12.9784 10 13.2384 10 13.4984C10 13.5584 9.99 13.6284 9.98 13.6984C9.97 13.7584 9.95 13.8184 9.92 13.8784C9.9 13.9384 9.87 13.9984 9.83 14.0584C9.8 14.1084 9.75 14.1583 9.71 14.2083C9.52 14.3884 9.26 14.4984 9 14.4984Z" fill={color || '#243E69'} />
        <path d="M12.5 14.4989C12.37 14.4989 12.24 14.4689 12.12 14.4189C11.99 14.3689 11.89 14.2989 11.79 14.2089C11.61 14.0189 11.5 13.7689 11.5 13.4989C11.5 13.3689 11.53 13.2389 11.58 13.1189C11.63 12.9989 11.7 12.8889 11.79 12.7889C11.89 12.6989 11.99 12.6289 12.12 12.5789C12.48 12.4189 12.93 12.5089 13.21 12.7889C13.39 12.9789 13.5 13.2389 13.5 13.4989C13.5 13.5589 13.49 13.6289 13.48 13.6989C13.47 13.7589 13.45 13.8189 13.42 13.8789C13.4 13.9389 13.37 13.9989 13.33 14.0589C13.3 14.1089 13.25 14.1589 13.21 14.2089C13.02 14.3889 12.76 14.4989 12.5 14.4989Z" fill={color || '#243E69'} />
        <path d="M16 14.4989C15.87 14.4989 15.74 14.4689 15.62 14.4189C15.49 14.3689 15.39 14.2989 15.29 14.2089C15.25 14.1589 15.21 14.1089 15.17 14.0589C15.13 13.9989 15.1 13.9389 15.08 13.8789C15.05 13.8189 15.03 13.7589 15.02 13.6989C15.01 13.6289 15 13.5589 15 13.4989C15 13.2389 15.11 12.9789 15.29 12.7889C15.39 12.6989 15.49 12.6289 15.62 12.5789C15.99 12.4189 16.43 12.5089 16.71 12.7889C16.89 12.9789 17 13.2389 17 13.4989C17 13.5589 16.99 13.6289 16.98 13.6989C16.97 13.7589 16.95 13.8189 16.92 13.8789C16.9 13.9389 16.87 13.9989 16.83 14.0589C16.8 14.1089 16.75 14.1589 16.71 14.2089C16.52 14.3889 16.26 14.4989 16 14.4989Z" fill={color || '#243E69'} />
        <path d="M9 17.9992C8.87 17.9992 8.74 17.9692 8.62 17.9192C8.5 17.8692 8.39 17.7992 8.29 17.7092C8.11 17.5192 8 17.2592 8 16.9992C8 16.8692 8.03 16.7392 8.08 16.6192C8.13 16.4892 8.2 16.3792 8.29 16.2892C8.66 15.9192 9.34 15.9192 9.71 16.2892C9.89 16.4792 10 16.7392 10 16.9992C10 17.2592 9.89 17.5192 9.71 17.7092C9.52 17.8892 9.26 17.9992 9 17.9992Z" fill={color || '#243E69'} />
        <path d="M12.5 17.9992C12.24 17.9992 11.98 17.8892 11.79 17.7092C11.61 17.5192 11.5 17.2592 11.5 16.9992C11.5 16.8692 11.53 16.7392 11.58 16.6192C11.63 16.4892 11.7 16.3792 11.79 16.2892C12.16 15.9192 12.84 15.9192 13.21 16.2892C13.3 16.3792 13.37 16.4892 13.42 16.6192C13.47 16.7392 13.5 16.8692 13.5 16.9992C13.5 17.2592 13.39 17.5192 13.21 17.7092C13.02 17.8892 12.76 17.9992 12.5 17.9992Z" fill={color || '#243E69'} />
        <path d="M16 18.0009C15.74 18.0009 15.48 17.8909 15.29 17.7109C15.2 17.6209 15.13 17.5109 15.08 17.3809C15.03 17.2609 15 17.1309 15 17.0009C15 16.8709 15.03 16.7409 15.08 16.6209C15.13 16.4909 15.2 16.3809 15.29 16.2909C15.52 16.0609 15.87 15.9509 16.19 16.0209C16.26 16.0309 16.32 16.0509 16.38 16.0809C16.44 16.1009 16.5 16.1309 16.56 16.1709C16.61 16.2009 16.66 16.2509 16.71 16.2909C16.89 16.4809 17 16.7409 17 17.0009C17 17.2609 16.89 17.5209 16.71 17.7109C16.52 17.8909 16.26 18.0009 16 18.0009Z" fill={color || '#243E69'} />
        <path d="M21 9.83984H4C3.59 9.83984 3.25 9.49984 3.25 9.08984C3.25 8.67984 3.59 8.33984 4 8.33984H21C21.41 8.33984 21.75 8.67984 21.75 9.08984C21.75 9.49984 21.41 9.83984 21 9.83984Z" fill={color || '#243E69'} />
        <path d="M16.5 22.75H8.5C4.85 22.75 2.75 20.65 2.75 17V8.5C2.75 4.85 4.85 2.75 8.5 2.75H16.5C20.15 2.75 22.25 4.85 22.25 8.5V17C22.25 20.65 20.15 22.75 16.5 22.75ZM8.5 4.25C5.64 4.25 4.25 5.64 4.25 8.5V17C4.25 19.86 5.64 21.25 8.5 21.25H16.5C19.36 21.25 20.75 19.86 20.75 17V8.5C20.75 5.64 19.36 4.25 16.5 4.25H8.5Z" fill={color || '#243E69'} />
    </svg>
)

export default Calendar;