import Wallet from "src/assets/Wallet";

import styles from './styles.module.css';

interface SignatureConfirmProps {
  price: number;
  setStep: (value: number) => void;
}

const SignatureConfirm = ({
  price,
  setStep
}: SignatureConfirmProps) => (
  <div className={styles.section}>
    <span className={styles.iconInfo}>
      <Wallet />
    </span>
    <h1 className={styles.title}>
      C’est presque fini !
    </h1>
    <h2 className={styles.subtitle}>
      La date de prise d'effet de votre complémentaire santé étant proche, nous vous invitons à
      effectuer le premier versement par carte bancaire afin de confirmer la mise en place de vos garanties.
      Les versements suivants seront bien automatiques.
    </h2>
    <h2 className={styles.subtitle + ' ' + styles.padding}>
      Le montant à payer est de <span className={styles.bold}>{price} €</span> pour ce premier mois.
    </h2>

    <button className={styles.button} onClick={() => setStep(5)}>
      Payer et finaliser ma souscription
    </button>
  </div>
)


export default SignatureConfirm;