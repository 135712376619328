import useSubscription from 'src/hooks/useSubscription';
import Guarantee from 'src/components/Subscription/Guarantee';
import PersonalInformation from 'src/components/Subscription/PersonalInformation';
import BankDetails from 'src/components/Subscription/BankDetails';
import SupportingDocuments from 'src/components/Subscription/SupportingDocuments';
import Signature from 'src/components/Subscription/Signature';
import Confirmation from 'src/components/Subscription/Confirmation';

import BaseLayout from 'src/layout/BaseLayout';

const SubscriptionForm = () => {
  const context = useSubscription();

  const { step, setStep, offer } = context;

  if (!offer) {
    return (
      <div className="flex flex-1 items-center justify-center h-96">
        <h1 className="text-4xl">404 Not Found</h1>
      </div>
    )
  }

  const steps = [
    {
      title: 'Votre garantie',
      component: <Guarantee />
    },
    {
      title: 'Informations personnelles',
      component: <PersonalInformation />
    },
    {
      title: 'Coordonnées bancaires',
      component: <BankDetails />
    },
    {
      title: 'Pièces justificatives',
      component: <SupportingDocuments />
    },
    {
      title: 'Signature',
      component: <Signature />
    },
    {
      title: 'Confirmation',
      component: <Confirmation />
    },
  ];

  return (
    <BaseLayout
      title="Souscrire en ligne"
      steps={steps}
      setStep={setStep}
      step={step}
    />
  );
}

export default SubscriptionForm;
