import { useState } from 'react';

import useSubscription from 'src/hooks/useSubscription';
import DevisResume from "src/components/Devis/Resume";
import NextButton from "src/components/Ui/NextButton";
import { devisData } from "src/data/DevisData";
import { formBankInvalid } from "src/services/validatorsSubscription";
import BankDetailsForm from "./Form";
import BankDetailsRefund from "./Refund";
import OffersModal from 'src/components/Ui/Modals/OffersModal';

import styles from './styles.module.css';

const BankDetails = () => {
  const [modal, setModal] = useState(false);
  const context = useSubscription();

  const { setStep, offer, bankDetailsInformations, setBankDetailsInformations } = context;

  const devis = devisData.find((devis) => devis.id === offer);

  return (
    <div className={`flex justify-center p-4 gap-12 rounded-md ${styles.responsivContainer}`}>
      <div className={`flex flex-col items-start space-y-4 relative overflow-hidden gap-12 ${styles.section}`}>
        <BankDetailsForm
          bankDetailsInformations={bankDetailsInformations}
          setBankDetailsInformations={setBankDetailsInformations}
        />
        <BankDetailsRefund
          bankDetailsInformations={bankDetailsInformations}
          setBankDetailsInformations={setBankDetailsInformations}
        />
      </div>
      {devis &&
        <div className="flex flex-col">
          <DevisResume devis={devis} withoutSubscribeButton actionConditions={() => setModal(true)} />
        </div>
      }

      <NextButton
        canPrev={true}
        canNext={!formBankInvalid({ bankDetailsInformations })}
        nextStep={() => {
          const stepToGo = 3;
          setStep(stepToGo);
        }}
        prevStep={() => setStep(1)}
      />
      {
        modal && (
          <OffersModal
            close={() => setModal(false)}
            isAthletic={'true'}
          />
        )
      }
    </div>
  );
}

export default BankDetails;