import styles from './styles.module.css';

interface AdditionalOffersProps {
  title: string;
  additionalComponent?: React.ReactNode;
  description: string;
  image: string;
  action: () => void;
}

const AdditionalOffers = ({
  title,
  additionalComponent,
  description,
  image,
  action
}: AdditionalOffersProps) => (
  <div className={styles.additionalInformationContainer}>
    <div className="relative">
      <div className={styles.image}
        style={{
          backgroundImage: `url(${image})`
        }}
      />
      {additionalComponent}
      <div className={styles.fadedBackground} />
    </div>
    <p className={styles.offerTitle}>{title}</p>
    <p className={styles.offerDescription}>{description}</p>
    <button onClick={action} className={styles.seeMore}>Voir les conditions</button>
  </div>
)

export default AdditionalOffers;