const Recieve = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
    <path d="M13 2.16602V8.66602L15.1667 6.49935" stroke="url(#paint0_linear_2791_31236)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.0007 8.66667L10.834 6.5" stroke="url(#paint1_linear_2791_31236)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.58333 13C3.25 13 3.25 14.9392 3.25 17.3333V18.4167C3.25 21.4067 3.25 23.8333 8.66667 23.8333H17.3333C21.6667 23.8333 22.75 21.4067 22.75 18.4167V17.3333C22.75 14.9392 22.75 13 18.4167 13C17.3333 13 17.03 13.2275 16.4667 13.65L15.3617 14.82C14.0833 16.185 11.9167 16.185 10.6275 14.82L9.53333 13.65C8.97 13.2275 8.66667 13 7.58333 13Z" stroke="url(#paint2_linear_2791_31236)" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.41602 13.0003V8.66695C5.41602 6.48945 5.41602 4.69112 8.66602 4.37695" stroke="url(#paint3_linear_2791_31236)" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M20.584 13.0003V8.66695C20.584 6.48945 20.584 4.69112 17.334 4.37695" stroke="url(#paint4_linear_2791_31236)" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <defs>
      <linearGradient id="paint0_linear_2791_31236" x1="13" y1="8.66602" x2="16.7471" y2="7.57023" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00642E" />
        <stop offset="1" stopColor="#9FC500" />
      </linearGradient>
      <linearGradient id="paint1_linear_2791_31236" x1="10.834" y1="8.66667" x2="13.1324" y2="6.6502" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00642E" />
        <stop offset="1" stopColor="#9FC500" />
      </linearGradient>
      <linearGradient id="paint2_linear_2791_31236" x1="3.25" y1="23.8333" x2="13.728" y2="7.28685" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00642E" />
        <stop offset="1" stopColor="#9FC500" />
      </linearGradient>
      <linearGradient id="paint3_linear_2791_31236" x1="5.41602" y1="13.0003" x2="10.916" y2="11.1817" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00642E" />
        <stop offset="1" stopColor="#9FC500" />
      </linearGradient>
      <linearGradient id="paint4_linear_2791_31236" x1="17.334" y1="13.0003" x2="22.834" y2="11.1817" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00642E" />
        <stop offset="1" stopColor="#9FC500" />
      </linearGradient>
    </defs>
  </svg>
)

export default Recieve;