import { DocumentsInformations } from 'src/types/Situation';
import Identity from 'src/assets/Identitity';
import InfoFull from 'src/assets/InfoFull';
import PickerDocument from 'src/components/Ui/PickerDocument';

import styles from './styles.module.css';

interface MyDocumentProps {
  documentsInformations: DocumentsInformations;
  setDocumentsInformations: (value: any) => void;
}

const MyDocuments = ({
  documentsInformations,
  setDocumentsInformations,
}: MyDocumentProps) => (
  <div className={styles.section}>
    <span className={styles.iconInfo}>
      <Identity />
    </span>
    <div className={styles.tooltip}>
      <span className={styles.tooltipImage}>
        <InfoFull />
      </span>
      <p>
        Afin de compléter votre demande de souscription, nous vous invitons à joindre les pièces justificatives. (5Mo max, pdf/jpeg/tiff)
      </p>
    </div>
    <h1 className={styles.titleSection}>Vous</h1>
    <label className={`mb-0 ${styles.label}`}>
      Justificatif d’identité en cours de validité
    </label>
    <label className={styles.label}>
      (carte d'identité, passeport) *
    </label>
    <PickerDocument
      document={documentsInformations.identityDocument}
      setDocument={(document: File) => {
        setDocumentsInformations((prev: DocumentsInformations) => ({
          ...prev,
          identityDocument: document,
        }))
      }}
    />
    <label className={styles.label}>
      Attestation Assurance maladie obligatoire *
    </label>
    <PickerDocument
      document={documentsInformations.healthInsuranceCertificate}
      setDocument={(document: File) => {
        setDocumentsInformations((prev: DocumentsInformations) => ({
          ...prev,
          healthInsuranceCertificate: document,
        }))
      }}
    />
    <label className={styles.label}>
      Relevé d’identité bancaire *
    </label>
    <PickerDocument
      document={documentsInformations.bankIdentityDocument}
      setDocument={(document: File) => {
        setDocumentsInformations((prev: DocumentsInformations) => ({
          ...prev,
          bankIdentityDocument: document,
        }))
      }}
    />
    <label className={styles.label}>
      Justificatif sportif *
    </label>
    <PickerDocument
      document={documentsInformations.sportCertificate}
      setDocument={(document: File) => {
        setDocumentsInformations((prev: DocumentsInformations) => ({
          ...prev,
          sportCertificate: document,
        }))
      }}
    />
  </div>
)

export default MyDocuments;