import { SubSpouseInformations } from 'src/types/Situation';
import Spouse from 'src/assets/Spouse';
import Input from 'src/components/Ui/Input';
import Select from 'src/components/Ui/Select';
import { socialSecurityConnectionValues } from 'src/data/DevisData';
import useSubscription from 'src/hooks/useSubscription';

import styles from './styles.module.css';

interface SpouseInformationProps {
  spouseInformations: SubSpouseInformations;
  setSpouseInformations: (value: any) => void;
}

const SpouseInformation = ({
  spouseInformations,
  setSpouseInformations,
}: SpouseInformationProps) => {
  const subscriptionContext = useSubscription();
  const { spouseActivity } = subscriptionContext;
  return (
  <div className={styles.section}>
    <span className={styles.iconInfo}>
      <Spouse />
    </span>
    <h1 className={styles.titleSection}>Votre conjoint</h1>
    <label htmlFor="civility" className={`flex items-center ${styles.label}`}>
      Civilité *
    </label>
    <div className="flex flex-row gap-4">
      <button
        className={`${styles.button} ${spouseInformations.civility === '2' ? styles.activ : ''}`}
        onClick={() => {
          setSpouseInformations((prevState: SubSpouseInformations) => ({
            ...prevState,
            civility: '2'
          }))
        }
        }>Madame</button>
      <button
        className={`${styles.button} ${spouseInformations.civility === '1' ? styles.activ : ''}`}
        onClick={() => {
          setSpouseInformations((prevState: SubSpouseInformations) => ({
            ...prevState,
            civility: '1'
          }))
        }
        }>Monsieur</button>
    </div>
    <Input
      label='Nom *'
      value={spouseInformations.lastname}
      onChange={(lastname) =>
        setSpouseInformations((prevState: SubSpouseInformations) => ({
          ...prevState,
          lastname
        }))
      }
      id='lastname'
    />
    <Input
      label='Prénom *'
      value={spouseInformations.firstname}
      onChange={(firstname) =>
        setSpouseInformations((prevState: SubSpouseInformations) => ({
          ...prevState,
          firstname
        }))
      }
      id='firstname'
    />
    <Input
      label='Date de naissance *'
      value={spouseInformations.birthday}
      onChange={(birthday) =>
        setSpouseInformations((prevState: SubSpouseInformations) => ({
          ...prevState,
          birthday
        }))
      }
      id='birthday'
      type='date'
    />
    <Select
      label='Rattachement *'
      value={spouseInformations.socialSecurityConnection}
      onChange={(socialSecurityConnection) =>
        setSpouseInformations((prevState: SubSpouseInformations) => ({
          ...prevState,
          socialSecurityConnection,
          otherRattachment: spouseInformations.socialSecurityConnection === 'other' ? '' : spouseInformations.otherRattachment
        }))
      }
      values={socialSecurityConnectionValues}
      noPlaceholder
      id='socialSecurityConnection'
    />
    {spouseInformations.socialSecurityConnection === 'other' && (
      <Input
        label='Numéro de sécurité sociale *'
        value={spouseInformations.otherRattachment}
        onChange={(otherRattachment) =>
          setSpouseInformations((prevState: SubSpouseInformations) => ({
            ...prevState,
            otherRattachment
          }))
        }
        id='otherRattachment'
        maxLength={15}
      />
    )}
    <Input
        label='Profession *'
        value={spouseActivity.name}
        onChange={() => {}}
        maxLength={20}
        id='activity'
      />
    <Select
      label='Sportif.ve ? Bénéficiez de 10% de réduction sur vos cotisations santé*'
      id='sport'
      disabled={true}
      value={spouseInformations.athletic}
      onChange={(athletic) => {
        setSpouseInformations((prevState: SubSpouseInformations) => ({
          ...prevState,
          athletic
        }))
      }}
      values={[
        { l: 'Oui', v: 'true' },
        { l: 'Non', v: 'false' }
      ]}
      noPlaceholder
    />
  </div>
)}

export default SpouseInformation;