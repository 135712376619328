const Email = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="111" height="106" viewBox="0 0 111 106" fill="none">
    <path d="M68 -5.88867V28.1113L79.3333 16.778" stroke="url(#paint0_linear_2805_25216)" strokeOpacity="0.16" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M68.0013 28.1126L56.668 16.7793" stroke="url(#paint1_linear_2805_25216)" strokeOpacity="0.16" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M39.6667 38C17 38 17 48.1433 17 60.6667V66.3333C17 81.9733 17 94.6667 45.3333 94.6667H90.6667C113.333 94.6667 119 81.9733 119 66.3333V60.6667C119 48.1433 119 38 96.3333 38C90.6667 38 89.08 39.19 86.1333 41.4L80.3533 47.52C73.6667 54.66 62.3333 54.66 55.59 47.52L49.8667 41.4C46.92 39.19 45.3333 38 39.6667 38Z" stroke="url(#paint2_linear_2805_25216)" strokeOpacity="0.16" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M28.334 37.9992V15.3326C28.334 3.94258 28.334 -5.46409 45.334 -7.10742" stroke="url(#paint3_linear_2805_25216)" strokeOpacity="0.16" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M107.666 37.9992V15.3326C107.666 3.94258 107.666 -5.46409 90.666 -7.10742" stroke="url(#paint4_linear_2805_25216)" strokeOpacity="0.16" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <defs>
      <linearGradient id="paint0_linear_2805_25216" x1="68" y1="28.1113" x2="87.6" y2="22.3795" gradientUnits="userSpaceOnUse">
        <stop stopColor="#A1ACBF" />
        <stop offset="1" stopColor="#D6DFEF" />
      </linearGradient>
      <linearGradient id="paint1_linear_2805_25216" x1="56.668" y1="28.1126" x2="68.6906" y2="17.565" gradientUnits="userSpaceOnUse">
        <stop stopColor="#A1ACBF" />
        <stop offset="1" stopColor="#D6DFEF" />
      </linearGradient>
      <linearGradient id="paint2_linear_2805_25216" x1="17" y1="94.6667" x2="71.8078" y2="8.11582" gradientUnits="userSpaceOnUse">
        <stop stopColor="#A1ACBF" />
        <stop offset="1" stopColor="#D6DFEF" />
      </linearGradient>
      <linearGradient id="paint3_linear_2805_25216" x1="28.334" y1="37.9992" x2="57.1031" y2="28.4868" gradientUnits="userSpaceOnUse">
        <stop stopColor="#A1ACBF" />
        <stop offset="1" stopColor="#D6DFEF" />
      </linearGradient>
      <linearGradient id="paint4_linear_2805_25216" x1="90.666" y1="37.9992" x2="119.435" y2="28.4868" gradientUnits="userSpaceOnUse">
        <stop stopColor="#A1ACBF" />
        <stop offset="1" stopColor="#D6DFEF" />
      </linearGradient>
    </defs>
  </svg>
)

export default Email;