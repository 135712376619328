import {
  SubPersonalInformations,
  SubChildrenInformations,
  SubSpouseInformations,
  BankDetailsInformations,
  DocumentsInformations,
  GuaranteeInformations
} from "src/types/Situation";

import { isInseeValid } from "src/services/socialSecuritySchemeUtils";
import { isDateValid, adhHasMajority } from "src/services/dateUtils";
import { isEmailValid } from "src/services/emailUtils";

// SUBSCRIBTION FORM
const formInvalid = ({
  personalInformations,
  spouseInformations,
  childrenInformations,
}: {
  personalInformations: SubPersonalInformations;
  spouseInformations: SubSpouseInformations;
  childrenInformations: SubChildrenInformations[];
}) => {
  if (
    !personalInformations.civility || personalInformations.civility.length > 1 ||
    !personalInformations.lastname || personalInformations.lastname.length > 25 ||
    !personalInformations.firstname || personalInformations.firstname.length > 15 ||
    !personalInformations.birthday || personalInformations.birthday.length > 10 || !isDateValid(personalInformations.birthday) || !adhHasMajority(personalInformations.birthday) ||
    !personalInformations.streetName || personalInformations.streetName.length > 27 ||
    !personalInformations.streetNumber || personalInformations.streetNumber.length > 4 ||
   ( personalInformations.streetMore && personalInformations.streetMore.length > 32) ||
    !personalInformations.postalCode || personalInformations.postalCode.length !== 5 ||
    !personalInformations.country || personalInformations.country.length > 50 ||
    !personalInformations.email || !isEmailValid(personalInformations.email) ||
    !personalInformations.phone || personalInformations.phone.length > 10 ||
    !personalInformations.insee || personalInformations.insee.length !== 15 || !isInseeValid(personalInformations.insee) ||
    !personalInformations.activity || personalInformations.activity.length > 2 ||
    !personalInformations.city || personalInformations.city.length > 50 ||
    !personalInformations.check
  ) {
    return true;
  }

  if (personalInformations.currentState === 'allMyFamily' || personalInformations.currentState === 'meAndMySpouse') {
    if (
      !spouseInformations.civility || spouseInformations.civility.length > 1 ||
      !spouseInformations.lastname || spouseInformations.lastname.length > 25 ||
      !spouseInformations.firstname || spouseInformations.firstname.length > 15 ||
      !spouseInformations.birthday || spouseInformations.birthday.length > 10 || !isDateValid(spouseInformations.birthday) || !adhHasMajority(spouseInformations.birthday) ||
      !spouseInformations.activity || spouseInformations.activity.length > 2 ||
      (spouseInformations.socialSecurityConnection === 'other' && !spouseInformations.otherRattachment)
    ) {
      return true;
    }
  }

  if (personalInformations.currentState === 'allMyFamily' || personalInformations.currentState === 'meAndMyChildren') {
    for (const child of childrenInformations) {
      if (
        !child.civility || child.civility.length > 1 ||
        !child.lastname || child.lastname.length > 25 ||
        !child.firstname || child.firstname.length > 15 ||
        !child.birthday || child.birthday.length > 10 || !isDateValid(child.birthday) ||
        (child.socialSecurityConnection === 'other' && !child.otherRattachment)
      ) {
        return true;
      }
    }
  }

  return false;
}

const formBankInvalid = ({
  bankDetailsInformations,
}: {
  bankDetailsInformations: BankDetailsInformations;
}) => {
  if (
    !bankDetailsInformations.ownerRib || bankDetailsInformations.ownerRib.length > 23 ||
    !bankDetailsInformations.rib || bankDetailsInformations.rib.length > 23 ||
    !bankDetailsInformations.ibanFee || bankDetailsInformations.ibanFee.length > 34 ||
    !bankDetailsInformations.bicFee || bankDetailsInformations.bicFee.length > 11 ||
    !bankDetailsInformations.dateFee || bankDetailsInformations.dateFee.length > 20
  ) {
    return true;
  }

  if (!bankDetailsInformations.isOwner) {
    if (
      !bankDetailsInformations.additionalAccountAdress || bankDetailsInformations.additionalAccountAdress.length > 100 ||
      !bankDetailsInformations.additionalAccountFirstName || bankDetailsInformations.additionalAccountFirstName.length > 50 ||
      !bankDetailsInformations.additionalAccountLastName || bankDetailsInformations.additionalAccountLastName.length > 50 ||
      !bankDetailsInformations.additionalAccountCity || bankDetailsInformations.additionalAccountCity.length > 50 ||
      !bankDetailsInformations.additionalAccountPostalCode || bankDetailsInformations.additionalAccountPostalCode.length !== 5
    ) {
      return true;
    }
  }

  if (!bankDetailsInformations.sameHolderFee) {
    if (
      !bankDetailsInformations.refundAccoundRib || bankDetailsInformations.refundAccoundRib.length > 23 ||
      !bankDetailsInformations.refundAccountBic || bankDetailsInformations.refundAccountBic.length !== 11 ||
      !bankDetailsInformations.refundAccountIban || bankDetailsInformations.refundAccountIban.length > 34 ||
      !bankDetailsInformations.refundAccoundOwner || bankDetailsInformations.refundAccoundOwner.length > 50
    ) {
      return true;
    }
  }

  if (!bankDetailsInformations.refundSpouseSameAccount) {
    if (
      !bankDetailsInformations.refundSpouseAccoundRib || bankDetailsInformations.refundSpouseAccoundRib.length > 23 ||
      !bankDetailsInformations.refundSpouseAccountBic || bankDetailsInformations.refundSpouseAccountBic.length !== 11 ||
      !bankDetailsInformations.refundSpouseAccountIban || bankDetailsInformations.refundSpouseAccountIban.length > 34 ||
      !bankDetailsInformations.refundSpouseAccoundOwner || bankDetailsInformations.refundSpouseAccoundOwner.length > 50
    ) {
      return true;
    }
  }

  return false;
}

const formDocumentsInformationsInvalid = ({
  documentsInformations,
}: {
  documentsInformations: DocumentsInformations
}) => (
  !documentsInformations.bankIdentityDocument ||
  !documentsInformations.healthInsuranceCertificate ||
  !documentsInformations.identifySpouseDocument ||
  !documentsInformations.identityDocument ||
  !documentsInformations.sportCertificate
)

const formGuaranteeInformationsInvalid = ({
  guaranteeInformations,
}: {
  guaranteeInformations: GuaranteeInformations
}) => {
  if (!guaranteeInformations.startingDate) {
    return true
  }

  if (guaranteeInformations.hasBeenSponsored) {
    if (!guaranteeInformations.sponsoredName ||
      !guaranteeInformations.sponsoredReference) {
      return true
    }
  }

  if (guaranteeInformations.hasComplementaryHealthInsurance && guaranteeInformations.needWipeOut) {
    if (!guaranteeInformations.currentHealthInsuranceName ||
      !guaranteeInformations.currentHealthInsuranceReference ||
      !guaranteeInformations.adressWipeOut ||
      !guaranteeInformations.postalCodeWipeOut ||
      !guaranteeInformations.cityWipeOut ||
      !guaranteeInformations.countryWipeOut
    ) {
      return true
    }
  }

  return false;
}

export {
  formInvalid,
  formBankInvalid,
  formDocumentsInformationsInvalid,
  formGuaranteeInformationsInvalid
}