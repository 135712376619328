import { useLayoutEffect, useRef, useState } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import ScrollTrigger from 'gsap/ScrollTrigger';

import { Devis } from 'src/types/Situation';
import Star from 'src/assets/Devis/Star';
import Download from 'src/assets/Needed/Download';
import Dropdown from "src/assets/Dropdown";
import { categories } from "src/data/DevisData";
import Dental from "src/assets/Needed/Dental";
import Optic from "src/assets/Needed/Optic";
import Hospitalization from "src/assets/Needed/Hospitalization";
import CurrentCare from "src/assets/Needed/CurrentCare";
import Hearing from "src/assets/Needed/Hearing";

import styles from './styles.module.css';

interface DropdownProps {
  values: { label: string, reduction: string }[];
  height: number;
}

const DropdownContent = ({ values, height }: DropdownProps) => (
  <div className="flex flex-col gap-2 mt-4 mb-4 w-full" style={{  }}>
    {values.map(({ label, reduction }, index) => (
      <div key={index} className={`flex flex-row w-full justify-around ${styles.dropdownContent}`} style={{ height: 22 }}>
        <span>{label}</span>
        <span style={{ opacity: 0.6 }}>{reduction}</span>
      </div>
    ))}
  </div>
);

interface CategoryProps {
  inverted: boolean;
  label: string;
  icon: React.ReactNode;
  value: string;
  contents: (string | string[])[] | null;
  setDropdownSelected: (key: string | null) => void;
  dropdownSelected: string | null;
}

const Category = ({ inverted, label, icon, value, contents, setDropdownSelected, dropdownSelected }: CategoryProps) => (
  <div className={`flex items-start flex-col w-full`}>
    <span
      onClick={() => {
        setDropdownSelected(value === dropdownSelected ? null : value)
      }}
      className="cursor-pointer mr-2 flex flex-row">
      {contents && <span
        className={`${styles.dropdown} ${dropdownSelected === value ? styles.dropdownSelected : ''}`}
      >
        <Dropdown color={inverted ? '#fff' : '#243E69'} />
      </span>
      }
      {icon}
      <span className={`${styles.categoriesLabel} flex flex-col gap-1`}>
        {label}
      </span>
    </span>
    {contents && dropdownSelected === value && <span className={`flex flex-col gap-2 mt-4 ${dropdownSelected === value ? styles.dropdownOpen : styles.dropdownClose}`}>
      {contents.map((content, index) => {
        if (typeof content === 'string') {
          return <span key={'content' + value + (index + 1)} className={styles.contentTitle}>{content}</span>
        }
        return (
          <ul key={'content' + value + (index + 1)} className="ml-4">
            {content.map((subContent) => (
              <li key={subContent} className={styles.contentSubContent}>{subContent}</li>
            ))}
          </ul>
        )
      })}
    </span>}
  </div>
);

const StarRating: React.FC<{ score: number, inverted: boolean, selected?: boolean }> = ({ score, inverted = false, selected = false }) => (
  <div className="flex gap-2 items-center" style={{ marginTop: 35, marginBottom: selected ? 4 : 34 }}>
    {[...Array(5)].map((_, index) => (
      <Star
        key={'arrow' + (index + 1)}
        full={index < score}
        inverted={inverted}
        half={
          index === score - 0.5
        } />
    ))}
  </div>
);

gsap.registerPlugin(ScrollTrigger);

interface DevisCardProps {
  devis: Devis;
  setOffer: (offer: number) => void;
  dropdownHeight: { height: number, key: string | null };
}

const DevisCard = ({
  devis,
  setOffer,
  dropdownHeight
}: DevisCardProps) => {
  const container = useRef<HTMLDivElement>(null);
  const header = useRef<HTMLDivElement>(null);

  const [dropdownSelected, setDropdownSelected] = useState<string | null>(null);
  const [isMobile, setIsMobile] = useState(false);

  useLayoutEffect(() => {
    function updateSize() {
      setIsMobile(window.innerWidth < 768);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useGSAP(() => {
    // keep header on top of the screen when scrolling in the container
    const ctx = gsap.context(() => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: container.current,
          start: 'top top',
          end: 'bottom top',
          pinSpacing: false,
          scrub: 1,
        }
      });

      tl.fromTo(
        header.current,
        { y: 0 },
        { y: (container.current && header.current) ? (container.current.clientHeight) : 0, ease: 'none' },
      );
    })

    return () => ctx.revert();
  }, { scope: container });

  const dental = categories.find(({ key }) => key === 'dental');
  const optical = categories.find(({ key }) => key === 'optical');
  const hospitalization = categories.find(({ key }) => key === 'hospitalization');
  const currentCare = categories.find(({ key }) => key === 'currentCare');
  const hearing = categories.find(({ key }) => key === 'hearing');

  return (
    <div ref={container} className={`${styles.cardContainer} ${devis.promoted ? styles.selected : ''}`}>
      <div className={styles.header + ' ' + (devis.promoted ? styles.headerSelected : '')} ref={header}>
        <div className={styles.banner + ' ' + (devis.promoted ? styles.bannerChoose : '')} />
        <h2 className="text-2xl font-bold text-center mb-4">{devis.name}</h2>
        <p className="text-center text-3xl font-semibold mb-2">{devis.price}€<span className={styles.tiny}>/ mois*</span></p>
        <div className="pl-6 pr-6">
          <button
            onClick={() => {
              setOffer(devis.id);
            }}
            className={`w-full py-2 rounded-full mb-4 ${styles.buttonChoose} ${devis.promoted ? styles.buttonInverted : ''}`}>
            Choisir cette garantie
          </button>
        </div>
      </div>
      <div className={styles.contentContainer}>
        <div className="flex flex-col items-center">
          {isMobile &&
            <Category
              inverted={devis.promoted}
              label="Dentaire"
              icon={<Dental color={devis.promoted ? '#fff' : '#243E69'} />}
              value="dental"
              contents={dental?.contents || null}
              setDropdownSelected={setDropdownSelected}
              dropdownSelected={dropdownSelected}
            />
          }
          <StarRating score={devis.dentalScore} inverted={devis.promoted} selected={dropdownHeight.key === 'dental'} />
          {!isMobile && dropdownHeight.key === 'dental' &&
            <DropdownContent values={devis.dentalDropdown} height={dropdownHeight.height} />
          }
          {isMobile &&
            <Category
              inverted={devis.promoted}
              label="Optique"
              icon={<Optic color={devis.promoted ? '#fff' : '#243E69'} />}
              value="optical"
              contents={optical?.contents || null}
              setDropdownSelected={setDropdownSelected}
              dropdownSelected={dropdownSelected}
            />
          }
          <StarRating score={devis.opticalScore} inverted={devis.promoted} selected={dropdownHeight.key === 'optical'} />
          {!isMobile && dropdownHeight.key === 'optical' &&
            <DropdownContent values={devis.opticalDropdown} height={dropdownHeight.height} />
          }
          {isMobile &&
            <Category
              inverted={devis.promoted}
              label="Hospitalisation"
              icon={<Hospitalization color={devis.promoted ? '#fff' : '#243E69'} />}
              value="hospitalization"
              contents={hospitalization?.contents || null}
              setDropdownSelected={setDropdownSelected}
              dropdownSelected={dropdownSelected}
            />
          }
          <StarRating score={devis.hospitalizationScore} inverted={devis.promoted} selected={dropdownHeight.key === 'hospitalization'} />
          {!isMobile && dropdownHeight.key === 'hospitalization' &&
            <DropdownContent values={devis.hospitalizationDropdown} height={dropdownHeight.height} />
          }
          {isMobile &&
            <Category
              inverted={devis.promoted}
              label="Soins courants"
              icon={<CurrentCare color={devis.promoted ? '#fff' : '#243E69'} />}
              value="currentCare"
              contents={currentCare?.contents || null}
              setDropdownSelected={setDropdownSelected}
              dropdownSelected={dropdownSelected}
            />
          }
          <StarRating score={devis.standardCareScore} inverted={devis.promoted} selected={dropdownHeight.key === 'currentCare'} />
          {!isMobile && dropdownHeight.key === 'currentCare' &&
            <DropdownContent values={devis.standardCareDropdown} height={dropdownHeight.height} />
          }
          {isMobile &&
            <Category
              inverted={devis.promoted}
              label="Audition"
              icon={<Hearing color={devis.promoted ? '#fff' : '#243E69'} />}
              value="hearing"
              contents={hearing?.contents || null}
              setDropdownSelected={setDropdownSelected}
              dropdownSelected={dropdownSelected}
            />
          }
          <StarRating score={devis.hearingScore} inverted={devis.promoted} selected={dropdownHeight.key === 'hearing'} />
          {!isMobile && dropdownHeight.key === 'hearing' &&
            <DropdownContent values={devis.hearingDropdown} height={dropdownHeight.height} />
          }
        </div>
        <ul className={`list-disc list-inside ${styles.list}`}>
          {devis.includedServices.map((service, index) => (
            <li key={index}>{service}</li>
          ))}
        </ul>
        <ul className={`list-disc list-inside ${styles.list} ${styles.lastList}`}>
          {devis.includedBenetifs.map((benefit, index) => (
            <li key={index}>{benefit}</li>
          ))}
        </ul>
        <div className="space-y-2">
          {devis.documents.map((doc, index) => (
            <a key={index} target='_blank' rel='noreferrer' href={doc.url} className="flex font-bold	items-center justify-between">
              {doc.title}
              <Download />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}

export default DevisCard;