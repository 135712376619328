import { useState } from 'react';

import useDevis from 'src/hooks/useDevis';
import { countries, phonePrefix } from "src/data/Form";
import { Devis, PersonalInformations, UserInformations } from "src/types/Situation";
import EmailButton from "src/assets/EmailButton";
import Email from "src/assets/Email";
import DevisResume from "../Resume";
import Input from "src/components/Ui/Input";
import { formDevisInformationsInvalid } from "src/services/validatorsDevis";
import Select from "src/components/Ui/Select";
import GuaranteeCard from './GuaranteeCard';
import OffersModal from 'src/components/Ui/Modals/OffersModal';
import NextButton from 'src/components/Ui/NextButton';

import styles from './styles.module.css';
import { submitForm } from 'src/services/api';

const Informations = () => {
  const [modal, setModal] = useState(false);
  const context = useDevis();

  const { setStep, offer, personalInformations, setPersonalInformations, offers, situationState, cities, setQuoteNumber } = context;

  const devis = offers.find((devis) => devis.id === offer);

  const handleClick = () => {
    if (devis === undefined) {
      return;
    }

    const userInformations: UserInformations = {
      accessionDate: situationState.accessionDate,
      activity: situationState.activity,
      athletic: 'true' === situationState.athletic,
      birthday: situationState.birthday,
      children: situationState.children,
      city: personalInformations.city,
      civility: personalInformations.civility,
      country: '0' === situationState.outside ? 'FR' : personalInformations.country,
      email: personalInformations.email,
      firstname: personalInformations.firstname,
      lastname: personalInformations.lastname,
      outside: '1' === situationState.outside,
      phone: personalInformations.phone,
      phoneAgreement: personalInformations.phoneAgreement,
      postalCode: situationState.postalCode,
      situation: situationState.situation,
      socialSecurityScheme: situationState.socialSecurityScheme,
      spouse: situationState.spouse,
      streetMore: personalInformations.streetMore,
      streetName: personalInformations.streetName,
      streetNumber: personalInformations.streetNumber,
      contractMode: null,
      feeDate: null,
      feePeriod: null,
      homePhone: null,
      insee: null,
      previouslyInsured: null,
      profession: null,
      socialSecurityFund: null,
    };

    const offers: Devis[] = [
      devis,
      ...personalInformations.guaranteesSelected
    ];

    submitForm(userInformations, offers).then((response) => {
      if (!response.quote_number) return;
      setQuoteNumber(response.quote_number);
    });
    
    setStep(4)
  };

  return (
    <div className={`flex justify-center p-4 gap-12 rounded-md ${styles.responsivContainer}`}>
      <div>
        <div className={`flex flex-col items-start space-y-4 bg-blue-100 p-14 pt-4 relative overflow-hidden ${styles.section}`}>
          <span className={styles.iconInfo}>
            <Email />
          </span>
          <h1 className={styles.titleSection}>Recevoir mon devis par e-mail </h1>
          <label htmlFor="civility" className={`flex items-center ${styles.label}`}>
            Civilité *
          </label>
          <div className="flex flex-row gap-4">
            <button
              className={`${styles.button} ${personalInformations.civility === '2' ? styles.activ : ''}`}
              onClick={() => {
                setPersonalInformations((prevState: PersonalInformations) => ({
                  ...prevState,
                  civility: '2'
                }))
              }
              }>Madame</button>
            <button
              className={`${styles.button} ${personalInformations.civility === '1' ? styles.activ : ''}`}
              onClick={() => {
                setPersonalInformations((prevState: PersonalInformations) => ({
                  ...prevState,
                  civility: '1'
                }))
              }
              }>Monsieur</button>
          </div>
          <Input
            value={personalInformations.firstname}
            error={personalInformations.firstname === ''}
            onChange={(firstname) =>
              setPersonalInformations((prevState: PersonalInformations) => ({
                ...prevState,
                firstname: firstname as string
              }))
            }
            id='firstname'
            label="Prénom *"
            maxLength={15}
          />
          <Input
            value={personalInformations.lastname}
            error={personalInformations.lastname === ''}
            onChange={(lastname) =>
              setPersonalInformations((prevState: PersonalInformations) => ({
                ...prevState,
                lastname: lastname as string
              }))
            }
            id='lastname'
            label="Nom *"
            maxLength={25}
          />
          <Input
            value={personalInformations.email}
            error={personalInformations.email === ''}
            onChange={(email) =>
              setPersonalInformations((prevState: PersonalInformations) => ({
                ...prevState,
                email: email as string
              }))
            }
            id='email'
            type='email'
            label="Email *"
            maxLength={50}
          />
          <Input
            value={personalInformations.streetNumber}
            error={personalInformations.streetNumber === ''}
            onChange={(streetNumber) =>
              setPersonalInformations((prevState: PersonalInformations) => ({
                ...prevState,
                streetNumber: streetNumber as string
              }))
            }
            id='streetNumber'
            label="Numéro de rue *"
            maxLength={4}
          />
          <Input
            value={personalInformations.streetName}
            error={personalInformations.streetName === ''}
            onChange={(adress) =>
              setPersonalInformations((prevState: PersonalInformations) => ({
                ...prevState,
                streetName: adress as string
              }))
            }
            id='adress'
            label="Adresse *"
            maxLength={27}
          />
          <Input
            value={personalInformations.streetMore}
            error={personalInformations.streetMore === ''}
            onChange={(additionalAdress) =>
              setPersonalInformations((prevState: PersonalInformations) => ({
                ...prevState,
                streetMore: additionalAdress as string
              }))
            }
            id='additionalAdress'
            label="Complément d'adresse"
            maxLength={32}
          />
          <Input
            value={situationState.postalCode}
            error={situationState.postalCode === ''}
            onChange={() => {}}
            id='postalCode'
            label="Code postal *"
            maxLength={5}
            disabled={true}
          />
          <Select
              label={'Ville *'}
              value={personalInformations.city}
              error={personalInformations.city === ''}
              onChange={(city) =>
                setPersonalInformations((prevState: PersonalInformations) => ({
                  ...prevState,
                  city
                }))
              }
              id='city'
              values={cities.map(({ key, value }) => ({ v: key, l: value }))}
            />
          {'1' === situationState.outside && 
          <Select
            label="Pays *"
            id="country"
            value={personalInformations.country}
            error={personalInformations.country === ''}
            onChange={(country) =>
              setPersonalInformations((prevState: PersonalInformations) => ({
                ...prevState,
                country
              }))
            }
            values={countries.map((country) => ({ v: country.value, l: country.label }))}
          />}
          <label htmlFor="phone" className={`flex items-center ${styles.label}`}>
            Téléphone *
          </label>
          <div className="flex items-center gap-4 w-full">
            <div className={styles.selectBox}>
              <Select
                value={personalInformations.phonePrefix}
                onChange={(phonePrefix) => {
                  setPersonalInformations((prevState: PersonalInformations) => ({
                    ...prevState,
                    phonePrefix
                  }))
                }}
                id="phonePrefix"
                values={phonePrefix.map((prefix) => ({ v: prefix.value, l: prefix.label }))}
              />
            </div>
            <Input
              value={personalInformations.phone}
              error={personalInformations.phone === ''}
              onChange={(phoneNumber) => {
                if (!isNaN(Number(phoneNumber))) {
                  setPersonalInformations((prevState: PersonalInformations) => ({
                    ...prevState,
                    phone: phoneNumber as string
                  }))
                }
              }}
              maxLength={10}
              id="phone"
              type="text"
            />
          </div>
          <label htmlFor="phone" className={`flex items-center ${styles.label}`}>
            Ajouter une ou plusieurs garanties à votre devis ?
          </label>
          <div className="flex items-center gap-4 w-full">
            <button
              onClick={() => {
                setPersonalInformations((prevState: PersonalInformations) => ({
                  ...prevState,
                  moreGuarantee: true
                }))
              }}
              className={`${styles.button} ${personalInformations.moreGuarantee ? styles.activ : ''}`}>Oui</button>
            <button
              onClick={() => {
                setPersonalInformations((prevState: PersonalInformations) => ({
                  ...prevState,
                  moreGuarantee: false,
                  guaranteesSelected: []
                }))
              }}
              className={`${styles.button} ${!personalInformations.moreGuarantee ? styles.activ : ''}`}>Non</button>
          </div>
          {personalInformations.moreGuarantee && (
            <div className={styles.guaranteeContainer}>
              {offers.map((guarantee) => (
                <GuaranteeCard
                  key={guarantee.id}
                  guarantee={guarantee}
                  guaranteesSelected={personalInformations.guaranteesSelected}
                  onClick={(id: number): void => {
                    if (personalInformations.guaranteesSelected.map((g) => g.id).includes(id)) {
                      setPersonalInformations((prevState: PersonalInformations) => ({
                        ...prevState,
                        guaranteesSelected: personalInformations.guaranteesSelected.filter((g) => g.id !== id)
                      }))
                    } else {
                      setPersonalInformations((prevState: PersonalInformations) => ({
                        ...prevState,
                        guaranteesSelected: [...personalInformations.guaranteesSelected, guarantee]
                      }))
                    }
                  }}
                />
              ))}
            </div>
          )}
          <label htmlFor="phone" className={`flex items-center ${styles.label}`}>
            Je souhaite être rappelé
          </label>
          <div className="flex items-center gap-4 w-full">
            <button
              onClick={() => {
                setPersonalInformations((prevState: PersonalInformations) => ({
                  ...prevState,
                  phoneAgreement: true
                }))
              }}
              className={`${styles.button} ${personalInformations.phoneAgreement ? styles.activ : ''}`}>Oui</button>
            <button
              onClick={() => {
                setPersonalInformations((prevState: PersonalInformations) => ({
                  ...prevState,
                  phoneAgreement: false
                }))
              }}
              className={`${styles.button} ${!personalInformations.phoneAgreement ? styles.activ : ''}`}>Non</button>
          </div>
          <p className={styles.informations}>
            Les informations, strictement nécessaires,
            collectées directement auprès de vous font
            l'objet de traitements automatisés ayant
            pour finalité l'établissement et l'envoi
            d'une offre commerciale personalisée.
            Les informations marquées d'un astérisque
            sur le formulaire de devis sont obligatoires
            et nécessaires. A défaut, La Mutuelle Verte
            ne sera pas en mesure d'établir et d'envoyer
            une offre commerciale personalisée. Pour toute
            information sur vos droits et sur les conditions
            de traitement de vos données à caractère personnel,
            consultez la rubrique relative à la protection des données.
          </p>
          <div className="flex w-full justify-end">
            <button
              disabled={formDevisInformationsInvalid({ personalInformations, situationState })}
              className={`${styles.buttonEmail} ${formDevisInformationsInvalid({ personalInformations, situationState }) ? styles.disabled : ''}`}
              onClick={handleClick}
            >
              Recevoir mon devis
              <EmailButton />
            </button>
          </div>
          <div className='flex justify-end w-full'>
            <p style={{ color: '#243E69', fontSize: '12px', fontWeight: 400 }}>(*) : champs obligatoires</p>
          </div>
        </div>
      </div>
      {devis &&
        <div className="flex flex-col">
          <DevisResume devis={devis} actionConditions={() => setModal(true)} />
        </div>
      }
      {
        modal && (
          <OffersModal
            close={() => setModal(false)}
            isAthletic={situationState.athletic}
          />
        )
      }
      <NextButton
        canPrev={true}
        canNext={false}
        nextStep={() => setStep(4)}
        prevStep={() => setStep(2)}
      />
    </div>
  )
}

export default Informations;