import { SubChildrenInformations } from 'src/types/Situation';
import Child from 'src/assets/Child';
import Input from 'src/components/Ui/Input';
import Select from 'src/components/Ui/Select';
import { socialSecurityConnectionValues } from 'src/data/DevisData';

import styles from './styles.module.css';

interface ChildrenInformationProps {
  childrenInformations: SubChildrenInformations[];
  setChildrenInformations: (value: any) => void;
}

const ChildrenInformation = ({
  childrenInformations,
  setChildrenInformations
}: ChildrenInformationProps) => (
  <div className={styles.section}>
    <span className={styles.iconInfo}>
      <Child />
    </span>
    <h1 className={styles.titleSection}>Vos enfants</h1>
    {
      childrenInformations.map((child, index) => (
        <div className={styles.childContainer} key={child.birthday + (index + 1)}>
          <p className={styles.childTitle}>Enfant {index + 1}</p>
          <label htmlFor="civility" className={`flex items-center ${styles.label}`}>
            Civilité *
          </label>
          <div className="flex flex-row gap-4">
            <button
              className={`${styles.button} ${child.civility === '2' ? styles.activ : ''}`}
              onClick={() => {
                const newChildren = [...childrenInformations];
                newChildren[index].civility = '2';
                setChildrenInformations(newChildren);
              }
              }>Madame</button>
            <button
              className={`${styles.button} ${child.civility === '1' ? styles.activ : ''}`}
              onClick={() => {
                const newChildren = [...childrenInformations];
                newChildren[index].civility = '1';
                setChildrenInformations(newChildren);
              }
              }>Monsieur</button>
          </div>
          <Input
            label='Nom *'
            value={child.lastname}
            onChange={(lastname) => {
              const newChildren = [...childrenInformations];
              newChildren[index].lastname = lastname as string;
              setChildrenInformations(newChildren);
            }}
            id='lastname'
          />
          <Input
            label='Prénom *'
            value={child.firstname}
            onChange={(firstname) => {
              const newChildren = [...childrenInformations];
              newChildren[index].firstname = firstname as string;
              setChildrenInformations(newChildren);
            }}
            id='firstname'
          />
          <Input
            label='Date de naissance *'
            value={child.birthday}
            onChange={(birthday) => {
              const newChildren = [...childrenInformations];
              newChildren[index].birthday = birthday as string;
              setChildrenInformations(newChildren);
            }}
            id='birthdate'
            type='date'
            disabled={child.birthday !== '' ? true : false}
            maxLength={10}
          />
          <Select
            label='Rattachement *'
            value={child.socialSecurityConnection}
            onChange={(socialSecurityConnection) => {
              const newChildren = [...childrenInformations];
              newChildren[index].socialSecurityConnection = socialSecurityConnection;
              if (socialSecurityConnection !== 'other') {
                newChildren[index].otherRattachment = '';
              }
              setChildrenInformations(newChildren);
            }}
            values={socialSecurityConnectionValues}
            id='socialSecurityConnection'
          />
          {child.socialSecurityConnection === 'other' && (
            <Input
              label='Numéro de sécurité sociale *'
              value={child.otherRattachment}
              onChange={(otherRattachment) => {
                const newChildren = [...childrenInformations];
                newChildren[index].otherRattachment = otherRattachment as string;
                setChildrenInformations(newChildren);
              }}
              id='otherRattachment'
              maxLength={15}
            />
          )}
        </div>
      ))
    }
  </div>
)

export default ChildrenInformation;