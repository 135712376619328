import useSubscription from 'src/hooks/useSubscription';
import Check from 'src/assets/Check';
import Download from 'src/assets/Download';
import DynamicBlock from 'src/components/Ui/DynamicBlock';
import { devisData } from 'src/data/DevisData';

import styles from './styles.module.css';

const Confirmation = () => {
  const context = useSubscription();

  const { offer, personalInformations, spouseInformations, childrenInformations, bankDetailsInformations, documentsInformations } = context;

  const devis = devisData.find((devis) => devis.id === offer);

  return (
    <div className={`flex justify-center items-start p-4 gap-12 rounded-md ${styles.responsivContainer}`}>
      <div className={`flex flex-col items-start space-y-4 relative overflow-hidden gap-12 ${styles.container}`}>
        <div className={styles.section}>
          <span className={styles.iconInfo}>
            <Check />
          </span>
          <h1 className={styles.title}>
            Votre souscription est terminée !
          </h1>
          <h2 className={styles.subtitle}>
            Merci de votre confiance et bienvenue parmi les adhérents de la Mutuelle Verte !
          </h2>
          <p className={styles.content}>
            Un email de confirmation de votre demande souscription vous a été envoyé avec vos documents remplis et signés électroniquement.
          </p>
          <p className={styles.content}>
            Nous vous informerons par voie postale de la validation de votre dossier par nos équipes. Bien entendu, nous restons à votre disposition pour vous apporter toute l'assistance et le conseil dont vous pourriez avoir besoin.
          </p>
          <p className={styles.contentSign}>
            L'équipe de La Mutuelle Verte.
          </p>
          <h2 className={styles.subtitle}>
            Vos documents à télécharger
          </h2>
          <button
            onClick={() => {
              window.open('https://www.google.com', '_blank');
            }}
            className={styles.button}>
            <span>
              Bulletin d’adhésion <span className={styles.buttonInformations}>(256 Ko PDF)</span>
            </span>
            <Download />
          </button>
          <button
            onClick={() => {
              window.open('https://www.google.com', '_blank');
            }}
            className={styles.button}>
            <span>
              Mandat de prélèvement SEPA <span className={styles.buttonInformations}>(256 Ko PDF)</span>
            </span>
            <Download />
          </button>
          <button
            onClick={() => {
              window.open('https://www.google.com', '_blank');
            }}
            className={styles.button}>
            <span>
              Coupon adhésion Offre “1 mois offert” <span className={styles.buttonInformations}>(256 Ko PDF)</span>
            </span>
            <Download />
          </button>
        </div>
      </div>
      <DynamicBlock
        image="/images/family.png"
        title="Besoin d’une assurance prévoyance en complément de votre offre santé ?"
        description="Arrêt de travail, invalidité, retraite, perte d’autonomie, décès…Protégez votre avenir et celui de votre famille."
        action={() => {
          console.log({
            devis,
            personalInformations,
            spouseInformations,
            childrenInformations,
            bankDetailsInformations,
            documentsInformations
          });
        }}
      />
    </div>
  );
}

export default Confirmation;