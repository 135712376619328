import React, { createContext, useState, ReactNode } from 'react';
import useDecodeUrl from '../hooks/useDecodeUrl';
import {
  Activity,
  BankDetailsInformations,
  City,
  DocumentsInformations,
  GuaranteeInformations,
  SubChildrenInformations,
  SubPersonalInformations,
  SubSpouseInformations
} from '../types/Situation';
import { useCityFetcher } from 'src/services/cityUtils';
import { useQuotePriceYearFetcher } from 'src/services/quotePriceYearUtils';
import { useActivityFetcher } from 'src/services/activityUtils';

interface SubscriptionContextProps {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  offer: number;
  guaranteeInformations: GuaranteeInformations;
  setGuaranteeInformations: React.Dispatch<React.SetStateAction<GuaranteeInformations>>;
  personalInformations: SubPersonalInformations;
  setPersonalInformations: React.Dispatch<React.SetStateAction<SubPersonalInformations>>;
  spouseInformations: SubSpouseInformations;
  setSpouseInformations: React.Dispatch<React.SetStateAction<SubSpouseInformations>>;
  childrenInformations: SubChildrenInformations[];
  setChildrenInformations: React.Dispatch<React.SetStateAction<SubChildrenInformations[]>>;
  bankDetailsInformations: BankDetailsInformations;
  setBankDetailsInformations: React.Dispatch<React.SetStateAction<BankDetailsInformations>>;
  documentsInformations: DocumentsInformations;
  setDocumentsInformations: React.Dispatch<React.SetStateAction<DocumentsInformations>>;
  signature: { subscription: string; mandate: string };
  setSignature: React.Dispatch<React.SetStateAction<{ subscription: string; mandate: string }>>;
  activity: Activity;
  spouseActivity: Activity;
  quotePriceYear: number;
  cities: Array<City>;
}

const SubscriptionContext = createContext<SubscriptionContextProps | undefined>(undefined);

const SubscriptionProvider = ({ children }: { children: ReactNode }) => {
  const data = useDecodeUrl();
  const [step, setStep] = useState(0);

  const offer = data.offer;

  const [guaranteeInformations, setGuaranteeInformations] = useState<GuaranteeInformations>({
    hasBeenSponsored: false,
    sponsoredName: '',
    sponsoredReference: '',
    hasComplementaryHealthInsurance: false,
    needWipeOut: false,
    currentHealthInsuranceReference: '',
    currentHealthInsuranceName: '',
    adressWipeOut: '',
    postalCodeWipeOut: '',
    cityWipeOut: '',
    countryWipeOut: '',
    startingDate: data.situationState.accessionDate,
  });

  const [personalInformations, setPersonalInformations] = useState<SubPersonalInformations>({
    civility: data.personalInformations.civility,
    firstname: data.personalInformations.firstname,
    lastname: data.personalInformations.lastname,
    birthday: data.situationState.birthday,
    streetName: data.personalInformations.streetName,
    streetNumber: data.personalInformations.streetNumber,
    streetMore: data.personalInformations.streetMore,
    postalCode: data.situationState.postalCode,
    city: data.personalInformations.city,
    country: data.personalInformations.country,
    email: data.personalInformations.email,
    phonePrefix: data.personalInformations.phonePrefix,
    phone: data.personalInformations.phone,
    phoneFixPrefix: '',
    phoneFix: '',
    insee: '',
    activity: data.situationState.activity,
    athletic: data.situationState.athletic,
    currentState: data.situationState.currentState,
    situation: data.situationState.situation,
    socialSecurityScheme: data.situationState.socialSecurityScheme,
    check: false
  });

  const [spouseInformations, setSpouseInformations] = useState<SubSpouseInformations>({
    civility: '',
    firstname: '',
    lastname: '',
    birthday: data.situationState.spouse.birthday,
    activity: data.situationState.spouse.activity,
    socialSecurityConnection: 'no',
    otherRattachment: '',
    athletic: data.situationState.spouse.athletic
  });

  const [childrenInformations, setChildrenInformations] = useState<SubChildrenInformations[]>(
    data.situationState.children.map(({ birthday }: SubChildrenInformations) => ({
      civility: '',
      firstname: '',
      lastname: '',
      birthday: birthday,
      socialSecurityConnection: 'no',
      otherRattachment: ''
    }))
  );

  const [bankDetailsInformations, setBankDetailsInformations] = useState<BankDetailsInformations>({
    ownerRib: '',
    rib: '',
    ibanFee: '',
    bicFee: '',
    dateFee: 'mensuel',
    isOwner: true,
    additionalAccountFirstName: '',
    additionalAccountLastName: '',
    additionalAccountAdress: '',
    additionalAccountPostalCode: '',
    additionalAccountCity: '',
    sameHolderFee: true,
    refundAccoundOwner: '',
    refundAccoundRib: '',
    refundAccountIban: '',
    refundAccountBic: '',
    refundSpouseSameAccount: true,
    refundSpouseAccoundOwner: '',
    refundSpouseAccoundRib: '',
    refundSpouseAccountIban: '',
    refundSpouseAccountBic: '',
  });

  const [documentsInformations, setDocumentsInformations] = useState<DocumentsInformations>({
    identityDocument: null,
    healthInsuranceCertificate: null,
    bankIdentityDocument: null,
    sportCertificate: null,
    identifySpouseDocument: null,
  });

  const [signature, setSignature] = useState({
    subscription: '',
    mandate: '',
  });

  const activity = useActivityFetcher(personalInformations.situation, personalInformations.socialSecurityScheme, personalInformations.activity);
  const spouseActivity = useActivityFetcher(personalInformations.situation, personalInformations.socialSecurityScheme, spouseInformations.activity, 'c');
  const quotePriceYear = useQuotePriceYearFetcher();
  const cities = useCityFetcher(personalInformations.postalCode);

  return (
    <SubscriptionContext.Provider value={{
      step,
      setStep,
      offer,
      guaranteeInformations,
      setGuaranteeInformations,
      personalInformations,
      setPersonalInformations,
      spouseInformations,
      setSpouseInformations,
      childrenInformations,
      setChildrenInformations,
      bankDetailsInformations,
      setBankDetailsInformations,
      documentsInformations,
      setDocumentsInformations,
      signature,
      setSignature,
      activity,
      quotePriceYear,
      cities,
      spouseActivity
    }}>
      {children}
    </SubscriptionContext.Provider>
  );
};

export { SubscriptionProvider, SubscriptionContext };
