import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  Route,
  Routes,
  BrowserRouter
} from "react-router-dom";

import { SubscriptionProvider } from './context/SubscriptionContext';
import DevisForm from './views/DevisForm';
import SubscriptionForm from './views/SubscriptionForm';
import Error404 from './views/404';
import reportWebVitals from './reportWebVitals';

import './index.css';

import "@fontsource/kumbh-sans";
import DevisLayout from './layout/DevisLayout';

const CustomRouting = () => (
  <Routes>
    <Route element={<DevisLayout />}>
      <Route
        path=""
        element={
          <DevisForm />
        } />
      <Route
        path="subscription"
        element={
          <SubscriptionProvider>
            <SubscriptionForm />
          </SubscriptionProvider>
        } />
    </Route>
    <Route path="*" element={<Error404 />} />
  </Routes>
)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <CustomRouting />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
