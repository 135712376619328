import { useContext } from 'react';
import { DevisContext } from 'src/context/DevisContext';

const useDevis = () => {
  const context = useContext(DevisContext);
  if (!context) {
    throw new Error('useDevis must be used within a DevisProvider');
  }
  return context;
}

export default useDevis;