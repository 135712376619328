const Check = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="83" height="111" viewBox="0 0 83 111" fill="none">
    <path d="M75.2979 42.6396C75.6204 42.9618 75.8763 43.3443 76.0508 43.7654C76.2254 44.1865 76.3152 44.6379 76.3152 45.0938C76.3152 45.5496 76.2254 46.001 76.0508 46.4221C75.8763 46.8432 75.6204 47.2257 75.2979 47.5479L51.0167 71.8291C50.6945 72.1517 50.3119 72.4075 49.8908 72.5821C49.4697 72.7566 49.0184 72.8465 48.5625 72.8465C48.1067 72.8465 47.6553 72.7566 47.2342 72.5821C46.8131 72.4075 46.4305 72.1517 46.1084 71.8291L35.7021 61.4229C35.0512 60.772 34.6856 59.8892 34.6856 58.9688C34.6856 58.0483 35.0512 57.1655 35.7021 56.5146C36.353 55.8637 37.2358 55.4981 38.1563 55.4981C39.0768 55.4981 39.9595 55.8637 40.6104 56.5146L48.5625 64.4711L70.3896 42.6396C70.7118 42.3171 71.0943 42.0612 71.5154 41.8867C71.9365 41.7121 72.3879 41.6223 72.8438 41.6223C73.2996 41.6223 73.751 41.7121 74.1721 41.8867C74.5932 42.0612 74.9758 42.3171 75.2979 42.6396ZM100.594 55.5C100.594 64.4187 97.9491 73.1371 92.9941 80.5527C88.0391 87.9684 80.9965 93.7482 72.7566 97.1612C64.5168 100.574 55.45 101.467 46.7027 99.7273C37.9553 97.9873 29.9204 93.6926 23.6139 87.3861C17.3074 81.0796 13.0127 73.0447 11.2727 64.2974C9.53278 55.55 10.4258 46.4832 13.8388 38.2434C17.2519 30.0036 23.0316 22.9609 30.4473 18.0059C37.8629 13.051 46.5813 10.4063 55.5 10.4062C67.4557 10.4189 78.9182 15.1739 87.3722 23.6278C95.8262 32.0818 100.581 43.5443 100.594 55.5ZM93.6563 55.5C93.6563 47.9534 91.4184 40.5763 87.2258 34.3015C83.0331 28.0268 77.0739 23.1362 70.1018 20.2482C63.1296 17.3603 55.4577 16.6046 48.0561 18.0769C40.6545 19.5492 33.8557 23.1832 28.5195 28.5195C23.1832 33.8557 19.5492 40.6545 18.0769 48.0561C16.6047 55.4577 17.3603 63.1296 20.2482 70.1018C23.1362 77.0739 28.0268 83.0331 34.3015 87.2258C40.5763 91.4184 47.9534 93.6562 55.5 93.6562C65.6162 93.6448 75.3147 89.6211 82.4679 82.4679C89.6211 75.3147 93.6448 65.6161 93.6563 55.5Z" fill="url(#paint0_linear_2672_39204)" stroke="#EAF3FF" strokeWidth="5" />
    <defs>
      <linearGradient id="paint0_linear_2672_39204" x1="10.4063" y1="100.594" x2="106.079" y2="16.6585" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00642E" />
        <stop offset="1" stopColor="#9FC500" />
      </linearGradient>
    </defs>
  </svg>
)

export default Check;