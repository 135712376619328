import Pen from "src/assets/Pen";

import styles from './styles.module.css';

interface SignatureDocumentProps {
  signature: string;
  setSignature: (value: string) => void;
  title: string;
  counter: number;
}

const SignatureDocument = ({
  signature,
  setSignature,
  title,
  counter,
}: SignatureDocumentProps) => (
  <div className={styles.section}>
    <span className={styles.iconInfo}>
      <Pen />
    </span>
    <div className={styles.counter}>
      {counter}/2
    </div>
    <h1 className={styles.title}>{title}</h1>
    <h2 className={styles.subtitle}>
      Afin de finaliser votre demande de souscription,
      nous vous invitons à contrôler puis signer électroniquement les documents suivants.
    </h2>
    <div className={styles.frame} onClick={() => setSignature('tmp')} />
  </div>
)


export default SignatureDocument;