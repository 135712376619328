import { BankDetailsInformations } from "src/types/Situation";
import Euro from "src/assets/Euro";

import styles from './styles.module.css';
import Input from "src/components/Ui/Input";

interface BankDetailsRefundProps {
  bankDetailsInformations: BankDetailsInformations;
  setBankDetailsInformations: (value: any) => void;
}

const BankDetailsRefund = ({
  bankDetailsInformations,
  setBankDetailsInformations,
}: BankDetailsRefundProps) => {
  return (
    <div className={styles.section}>
      <span className={styles.iconInfo}>
        <Euro />
      </span>
      <h1 className={styles.titleSection}>Remboursements des soins</h1>
      <label className={`flex items-center ${styles.label}`}>
        Souhaitez-vous que les remboursements des soins soient versés sur le compte utilisé pour les prélèvements ?
      </label>
      <div className="flex flex-row gap-4">
        <button
          className={`${styles.button} ${!bankDetailsInformations.sameHolderFee ? styles.activ : ''}`}
          onClick={() => {
            setBankDetailsInformations((prevState: BankDetailsInformations) => ({
              ...prevState,
              sameHolderFee: false,
              refundAccoundOwner: '',
              refundAccoundRib: '',
              refundAccountIban: '',
              refundAccountBic: ''
            }))
          }
          }>Non</button>
        <button
          className={`${styles.button} ${bankDetailsInformations.sameHolderFee ? styles.activ : ''}`}
          onClick={() => {
            setBankDetailsInformations((prevState: BankDetailsInformations) => ({
              ...prevState,
              sameHolderFee: true
            }))
          }
          }>Oui</button>
      </div>
      {!bankDetailsInformations.sameHolderFee && (
        <>
          <Input
            label='Titulaire du RIB *'
            value={bankDetailsInformations.refundAccoundOwner}
            onChange={(refundAccoundOwner) => {
              setBankDetailsInformations((prevState: BankDetailsInformations) => ({
                ...prevState,
                refundAccoundOwner
              }))
            }}
            id='refundAccoundOwner'
          />
          <Input
            label='RIB *'
            value={bankDetailsInformations.refundAccoundRib}
            onChange={(refundAccoundRib) => {
              setBankDetailsInformations((prevState: BankDetailsInformations) => ({
                ...prevState,
                refundAccoundRib
              }))
            }}
            id='refundAccoundRib'
          />
          <Input
            label='IBAN *'
            value={bankDetailsInformations.refundAccountIban}
            onChange={(refundAccountIban) => {
              setBankDetailsInformations((prevState: BankDetailsInformations) => ({
                ...prevState,
                refundAccountIban
              }))
            }}
            id='refundAccountIban'
          />
          <Input
            label='BIC *'
            value={bankDetailsInformations.refundAccountBic}
            onChange={(refundAccountBic) => {
              setBankDetailsInformations((prevState: BankDetailsInformations) => ({
                ...prevState,
                refundAccountBic
              }))
            }}
            id='refundAccountBic'
          />
        </>
      )}
      <label className={`flex items-center ${styles.label}`}>
        Souhaitez-vous que les remboursements de soins pour votre conjoint soient versés sur le même compte ?
      </label>
      <div className="flex flex-row gap-4">
        <button
          className={`${styles.button} ${!bankDetailsInformations.refundSpouseSameAccount ? styles.activ : ''}`}
          onClick={() => {
            setBankDetailsInformations((prevState: BankDetailsInformations) => ({
              ...prevState,
              refundSpouseSameAccount: false,
              refundSpouseAccoundOwner: '',
              refundSpouseAccoundRib: '',
              refundSpouseAccountIban: '',
              refundSpouseAccountBic: ''
            }))
          }
          }>Non</button>
        <button
          className={`${styles.button} ${bankDetailsInformations.refundSpouseSameAccount ? styles.activ : ''}`}
          onClick={() => {
            setBankDetailsInformations((prevState: BankDetailsInformations) => ({
              ...prevState,
              refundSpouseSameAccount: true
            }))
          }
          }>Oui</button>
      </div>
      {!bankDetailsInformations.refundSpouseSameAccount && (
        <>
          <Input
            label='Titulaire du RIB *'
            value={bankDetailsInformations.refundSpouseAccoundOwner}
            onChange={(refundSpouseAccoundOwner) => {
              setBankDetailsInformations((prevState: BankDetailsInformations) => ({
                ...prevState,
                refundSpouseAccoundOwner
              }))
            }}
            id='refundSpouseAccoundOwner'
          />
          <Input
            label='RIB *'
            value={bankDetailsInformations.refundSpouseAccoundRib}
            onChange={(refundSpouseAccoundRib) => {
              setBankDetailsInformations((prevState: BankDetailsInformations) => ({
                ...prevState,
                refundSpouseAccoundRib
              }))
            }}
            id='refundSpouseAccoundRib'
          />
          <Input
            label='IBAN *'
            value={bankDetailsInformations.refundSpouseAccountIban}
            onChange={(refundSpouseAccountIban) => {
              setBankDetailsInformations((prevState: BankDetailsInformations) => ({
                ...prevState,
                refundSpouseAccountIban
              }))
            }}
            id='refundSpouseAccountIban'
          />
          <Input
            label='BIC *'
            value={bankDetailsInformations.refundSpouseAccountBic}
            onChange={(refundSpouseAccountBic) => {
              setBankDetailsInformations((prevState: BankDetailsInformations) => ({
                ...prevState,
                refundSpouseAccountBic
              }))
            }}
            id='refundSpouseAccountBic'
          />
        </>
      )}
    </div>
  )
}

export default BankDetailsRefund;