import Done from 'src/assets/Done';

import styles from './styles.module.css';

interface NavCardProps {
  title: string;
  step: number;
  index: number;
}

const NavCard = ({ title, step, index }: NavCardProps) => {
  return (
    <div
      className={`${step === index ? styles.activ : ''} ${step > index ? styles.previous : ''} p-3 m-1 flex justify-center items-center gap-2 ${styles.card}`}
    >
      {step > index ?
        <Done />
        :
        <span className={styles.indicator}>
          {index + 1}
        </span>
      }
      {title}
    </div>
  )
}

export default NavCard;