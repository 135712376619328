const Gift = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
    <path d="M3.86198 9.58398H19.1953V17.2507C19.1953 20.1257 18.237 21.084 15.362 21.084H7.69531C4.82031 21.084 3.86198 20.1257 3.86198 17.2507V9.58398Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M2.39518 6.70768V7.66602C2.39518 8.72018 2.9031 9.58268 4.31185 9.58268H18.6868C20.1531 9.58268 20.6035 8.72018 20.6035 7.66602V6.70768C20.6035 5.65352 20.1531 4.79102 18.6868 4.79102H4.31185C2.9031 4.79102 2.39518 5.65352 2.39518 6.70768Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.8457 4.79253H17.1357C17.4615 4.43794 17.4519 3.89169 17.1069 3.54669L15.7461 2.18586C15.3915 1.83128 14.8069 1.83128 14.4523 2.18586L11.8457 4.79253Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.87406 4.79253H11.1641L8.5574 2.18586C8.20281 1.83128 7.61823 1.83128 7.26365 2.18586L5.90281 3.54669C5.55781 3.89169 5.54823 4.43794 5.87406 4.79253Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.4316 9.58398V14.5098C14.4316 15.2765 13.5883 15.7269 12.9462 15.3148L12.0454 14.7207C11.7196 14.5098 11.3075 14.5098 10.9912 14.7207L10.1383 15.2957C9.50581 15.7173 8.65289 15.2669 8.65289 14.5002V9.58398H14.4316Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export default Gift;