import React from 'react';

import styles from './styles.module.css'

interface InputProps {
  onChange: (value: string) => void
  value: string | number
  label?: string
  values: { v: string, l: string }[]
  id?: string
  noPlaceholder?: boolean
  disabled?: boolean
  error?: boolean
}

const Select = ({
  onChange,
  value,
  label,
  values,
  id = '',
  noPlaceholder = false,
  disabled = false,
  error = false
}: InputProps) => {
  const [hasError, setHasError] = React.useState(false)

  return (
  <>
    {label &&
      <label htmlFor={id} className={`flex items-center ${styles.label}`}>
        {label}
      </label>
    }
    <select
      disabled={disabled}
      className={styles.input}
      onChange={(e) => onChange(e.target.value)}
      value={value}
      id={id}
      onBlur={(e) => setHasError(error)}
      style={{
        color: value ? '#243E69' : '#767C83',
        borderColor: hasError ? '#FF2E4D' : '#D1D5DB'
      }}
    >
      {!noPlaceholder && <option value=''>Sélectionner</option>}
      {values.map(({ v, l }) => (
        <option key={v} value={v}>{l}</option>
      ))}
    </select>
    {hasError && <span className={styles.error}>Veuillez compléter ce champ</span>}
  </>
  )
}

export default Select;