const Pen = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="62" height="62" viewBox="0 0 62 62" fill="none">
    <path d="M43.7357 7.44269L43.7358 7.4428L54.5592 18.2637C54.8541 18.5586 55.088 18.9086 55.2476 19.2939L55.8943 19.026L55.2476 19.2939C55.4072 19.6791 55.4894 20.0921 55.4894 20.5091C55.4894 20.9261 55.4072 21.339 55.2476 21.7242L55.8491 21.9734L55.2476 21.7243C55.088 22.1095 54.8541 22.4595 54.5592 22.7544L54.5591 22.7544L27.4341 49.8794L26.2391 51.0744H27.9291H52.3125C52.6407 51.0744 52.9555 51.2048 53.1876 51.4369C53.4196 51.6689 53.55 51.9837 53.55 52.3119C53.55 52.6401 53.4196 52.9549 53.1876 53.187C52.9555 53.419 52.6407 53.5494 52.3125 53.5494H11.625C10.783 53.5494 9.97538 53.2149 9.37996 52.6195C8.78453 52.0241 8.45002 51.2165 8.45002 50.3744L8.45002 39.5511L8.45002 39.5488C8.44869 39.1325 8.53012 38.7201 8.68958 38.3356C8.84904 37.951 9.08334 37.602 9.37888 37.3089L9.38085 37.3069L39.245 7.44275L39.2451 7.44269C39.5399 7.14779 39.8899 6.91386 40.2752 6.75425C40.6604 6.59465 41.0734 6.5125 41.4904 6.5125C41.9074 6.5125 42.3203 6.59465 42.7056 6.75425L42.9735 6.10755L42.7056 6.75425C43.0908 6.91386 43.4409 7.14779 43.7357 7.44269ZM22.4484 51.0744H22.7383L22.9434 50.8694L44.2559 29.5569L44.7508 29.0619L44.2559 28.5669L33.4325 17.7436L32.9375 17.2486L32.4425 17.7436L11.13 39.0561L10.925 39.2611V39.5511V50.3744V51.0744H11.625H22.4484ZM46.005 26.8177L46.5 27.3128L46.995 26.8177L52.8075 21.0052L53.3024 20.5103L52.8075 20.0154L41.9866 9.192L41.4916 8.69691L40.9966 9.19194L35.1841 15.0044L34.6892 15.4994L35.1841 15.9943L46.005 26.8177Z" fill="url(#paint0_linear_3222_1430)" stroke="#EAF3FF" strokeWidth="1.4" />
    <defs>
      <linearGradient id="paint0_linear_3222_1430" x1="7.75" y1="54.2494" x2="59.1332" y2="9.16784" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00642E" />
        <stop offset="1" stopColor="#9FC500" />
      </linearGradient>
    </defs>
  </svg>
)

export default Pen;