const Upload = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
    <path d="M9.5 17.5V11.5L7.5 13.5" fill="#243E69" />
    <path d="M9.5 17.5V11.5L7.5 13.5" stroke="#243E69" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.5 11.5L11.5 13.5" stroke="#243E69" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M22.5 10.5V15.5C22.5 20.5 20.5 22.5 15.5 22.5H9.5C4.5 22.5 2.5 20.5 2.5 15.5V9.5C2.5 4.5 4.5 2.5 9.5 2.5H14.5" stroke="#243E69" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M22.5 10.5H18.5C15.5 10.5 14.5 9.5 14.5 6.5V2.5L22.5 10.5Z" stroke="#243E69" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export default Upload;