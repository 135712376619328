const countries = [
  { label: 'France', value: 'FR' },
  { label: 'Angleterre', value: 'EN' },
  { label: 'Allemagne', value: 'DE' },
  { label: 'Espagne', value: 'ES' },
  { label: 'Italie', value: 'IT' },
]

const phonePrefix = [
  { label: '+33', value: '+33' },
]

export {
  countries,
  phonePrefix
}