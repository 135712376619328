import { useEffect, useState } from "react";
import { getActivities } from "./api";
import { Activity } from "src/types/Situation";

export const useActivityFetcher = (situation: string, socialSecurityScheme: string, activityString: string, type: string = 'a', outside: string = '0'): Activity => {
    const [activity, setActivity] = useState({} as Activity);

    useEffect(() => {
        if (!['0', '1'].includes(outside) || !situation || !socialSecurityScheme) return;
    
        getActivities(outside, situation, socialSecurityScheme, type, activityString).then(data => {
            if (!data[0]) return;
            setActivity(data[0]);
        })
    }, [situation, socialSecurityScheme, activityString, outside, type]);

    return activity;
};

export const useActivitiesFetcher = (situation: string, socialSecurityScheme: string, outside: string = '0', type: string = 'a'): Activity[] => {
    const [activities, setActivities] = useState([] as Activity[]);
    useEffect(() => {
        if (!['0', '1'].includes(outside) || !situation || !socialSecurityScheme) return;

        getActivities(outside, situation, socialSecurityScheme, type).then(data => {
            setActivities(data);
        })
    }, [situation, socialSecurityScheme, outside, type]);

    return activities;
};