const Star = ({ full = false, inverted = false, half = false }) => {
  if (inverted) {
    if (half) {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
          <defs>
            <clipPath id="half-star">
              <rect width="9.1579" height="19" />
            </clipPath>
          </defs>
          <path d="M9.1579 0.449219L11.9572 6.2248L18.3158 7.10319L13.688 11.5509L14.8175 17.8691L9.1579 14.8416L3.49755 17.8691L4.62779 11.5509L0 7.10319L6.35863 6.2248L9.1579 0.449219Z" fill="white" clipPath="url(#half-star)" />
          <path d="M9.1579 0.449219L11.9572 6.2248L18.3158 7.10319L13.688 11.5509L14.8175 17.8691L9.1579 14.8416L3.49755 17.8691L4.62779 11.5509L0 7.10319L6.35863 6.2248L9.1579 0.449219Z" fill="none" stroke="white" />
        </svg>
      )
    }
    if (full) {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
          <g clipPath="url(#clip0_2644_27856)">
            <path d="M9.1579 0.449219L11.9572 6.2248L18.3158 7.10319L13.688 11.5509L14.8175 17.8691L9.1579 14.8416L3.49755 17.8691L4.62779 11.5509L0 7.10319L6.35863 6.2248L9.1579 0.449219Z" fill="white" />
          </g>
          <defs>
            <clipPath id="clip0_2644_27856">
              <rect width="18.3158" height="18.3158" fill="white" />
            </clipPath>
          </defs>
        </svg>
      )
    }
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
        <g clipPath="url(#clip0_2644_27860)">
          <path d="M9.84247 3.94906L11.6244 7.62519L15.673 8.18382L12.7264 11.0151L13.4453 15.037L9.84247 13.1108L6.2396 15.0377L6.95849 11.0159L4.01194 8.18459L8.06049 7.62519L9.84247 3.94906ZM9.84247 0.449219L7.0432 6.2248L0.68457 7.10319L5.31236 11.5509L4.18289 17.8691L9.84247 14.8416L15.502 17.8683L14.3726 11.5501L19.0004 7.10319L12.6417 6.22556L9.84247 0.449219Z" fill="white" />
        </g>
        <defs>
          <clipPath id="clip0_2644_27860">
            <rect width="18.3158" height="18.3158" fill="white" transform="translate(0.68457)" />
          </clipPath>
        </defs>
      </svg>
    )
  }
  if (half) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
        <defs>
          <clipPath id="half-star">
            <rect width="9.1579" height="19" />
          </clipPath>
        </defs>
        <path d="M9.1579 0.449219L11.9572 6.2248L18.3158 7.10319L13.688 11.5509L14.8175 17.8691L9.1579 14.8416L3.49755 17.8691L4.62779 11.5509L0 7.10319L6.35863 6.2248L9.1579 0.449219Z" fill="#243E69" clipPath="url(#half-star)" />
        <path d="M9.1579 0.449219L11.9572 6.2248L18.3158 7.10319L13.688 11.5509L14.8175 17.8691L9.1579 14.8416L3.49755 17.8691L4.62779 11.5509L0 7.10319L6.35863 6.2248L9.1579 0.449219Z" fill="none" stroke="#243E69" />
      </svg>
    )
  }
  if (full) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
        <g clipPath="url(#clip0_2644_27918)">
          <path d="M9.42059 0.449219L12.2199 6.2248L18.5785 7.10319L13.9507 11.5509L15.0802 17.8691L9.42059 14.8416L3.76025 17.8691L4.89048 11.5509L0.262695 7.10319L6.62133 6.2248L9.42059 0.449219Z" fill="#243E69" />
        </g>
        <defs>
          <clipPath id="clip0_2644_27918">
            <rect width="18.3158" height="18.3158" fill="white" transform="translate(0.262695)" />
          </clipPath>
        </defs>
      </svg>
    )
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
      <g clipPath="url(#clip0_2644_27919)">
        <path d="M9.84247 3.94906L11.6244 7.62519L15.673 8.18382L12.7264 11.0151L13.4453 15.037L9.84247 13.1108L6.2396 15.0377L6.95849 11.0159L4.01194 8.18459L8.06049 7.62519L9.84247 3.94906ZM9.84247 0.449219L7.0432 6.2248L0.68457 7.10319L5.31236 11.5509L4.18289 17.8691L9.84247 14.8416L15.502 17.8683L14.3726 11.5501L19.0004 7.10319L12.6417 6.22556L9.84247 0.449219Z" fill="#243E69" />
      </g>
      <defs>
        <clipPath id="clip0_2644_27919">
          <rect width="18.3158" height="18.3158" fill="white" transform="translate(0.68457)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Star;
