export default class LocalStorageUtils<T> {
    private static _instance: LocalStorageUtils<any> | null = null;

    private constructor() { }

    public static getInstance<U>(): LocalStorageUtils<U> {
        if (!LocalStorageUtils._instance) {
            LocalStorageUtils._instance = new LocalStorageUtils();
        }
        return LocalStorageUtils._instance as LocalStorageUtils<U>;
    }

    getItem(key: string): T | null {
        const storedItem = localStorage.getItem(key);
        return storedItem ? JSON.parse(storedItem) as T : null;
    }

    setItem = (key: string, item: T) => {
        localStorage.setItem(key, JSON.stringify(item));
    }
}
