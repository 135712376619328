import Arrow from 'src/assets/Arrow';

import styles from './styles.module.css';

interface NextButtonProps {
  canNext: boolean;
  canPrev: boolean;
  nextStep: () => void;
  prevStep: () => void;
}

const NextButton = ({
  canNext,
  canPrev,
  nextStep,
  prevStep,
}: NextButtonProps) => (
  <div className={styles.nextButtonContainer}>
    {canPrev && (
      <button disabled={!canPrev} className={`${styles.button} ${styles.buttonDisabled}`} onClick={() => prevStep()}>
        <span className={`${styles.iconReverse}`}>
          <Arrow color='#243E69' />
        </span>
        Précédent
      </button>
    )}
    {canNext && (
      <button disabled={!canNext} className={`${styles.button}`} onClick={() => nextStep()}>
        Suivant
        <span className={styles.icon}>
          <Arrow />
        </span>
      </button>
    )}
  </div>
)

export default NextButton;