const Bank = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="69" height="79" viewBox="0 0 69 79" fill="none">
    <path d="M47.9337 -5.66984L82.8087 8.28006C84.165 8.82256 85.25 10.45 85.25 11.8838V24.7488C85.25 26.8801 83.5063 28.6238 81.375 28.6238H11.625C9.49375 28.6238 7.75 26.8801 7.75 24.7488V11.8838C7.75 10.45 8.83502 8.82256 10.1913 8.28006L45.0663 -5.66984C45.8413 -5.97984 47.1587 -5.97984 47.9337 -5.66984Z" stroke="url(#paint0_linear_3222_11503)" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M85.25 71.25H7.75V59.625C7.75 57.4937 9.49375 55.75 11.625 55.75H81.375C83.5063 55.75 85.25 57.4937 85.25 59.625V71.25Z" stroke="url(#paint1_linear_3222_11503)" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15.5 55.75V28.625" stroke="#4C9064" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M31 55.75V28.625" stroke="#4C9064" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M46.5 55.75V28.625" stroke="#4C9064" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M62 55.75V28.625" stroke="#4C9064" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3.875 71.25H89.125" stroke="url(#paint2_linear_3222_11503)" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M46.5 18.9375C49.7102 18.9375 52.3125 16.3352 52.3125 13.125C52.3125 9.91484 49.7102 7.3125 46.5 7.3125C43.2898 7.3125 40.6875 9.91484 40.6875 13.125C40.6875 16.3352 43.2898 18.9375 46.5 18.9375Z" stroke="url(#paint3_linear_3222_11503)" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <defs>
      <linearGradient id="paint0_linear_3222_11503" x1="7.75" y1="28.6238" x2="37.5755" y2="-30.1113" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00642E" />
        <stop offset="1" stopColor="#9FC500" />
      </linearGradient>
      <linearGradient id="paint1_linear_3222_11503" x1="7.75" y1="71.25" x2="14.9376" y2="39.721" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00642E" />
        <stop offset="1" stopColor="#9FC500" />
      </linearGradient>
      <linearGradient id="paint2_linear_3222_11503" x1="3.875" y1="72.25" x2="3.90361" y2="70.1105" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00642E" />
        <stop offset="1" stopColor="#9FC500" />
      </linearGradient>
      <linearGradient id="paint3_linear_3222_11503" x1="40.6875" y1="18.9375" x2="53.0195" y2="8.11841" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00642E" />
        <stop offset="1" stopColor="#9FC500" />
      </linearGradient>
    </defs>
  </svg>
)

export default Bank;