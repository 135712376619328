const Dental = ({ color }: { color?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="23" height="24" viewBox="0 0 23 24">
    <path
      fill={color || "url(#a)"}
      d="M11.499 16.042c.016.034.416 1.47.606 2.25.271 1.114.618 2.216 1.151 2.928.5.667 1.147 1.054 1.98 1.054 1.042 0 2.077-.688 2.927-1.95 1.57-2.338 2.637-6.742 2.654-10.188.025-5.328-3.324-8.41-6.523-8.386a4.681 4.681 0 0 0-2.794.941 4.677 4.677 0 0 0-2.794-.94c-3.2-.026-6.548 3.058-6.523 8.362.016 3.463 1.102 7.872 2.67 10.209.849 1.265 1.882 1.952 2.911 1.952.826 0 1.47-.387 1.968-1.057.529-.71.874-1.81 1.15-2.921.193-.781.6-2.217.616-2.254ZM4.05 10.104c-.019-3.988 2.234-6.506 4.64-6.487.84.007 1.593.37 2.105.957a.934.934 0 0 0 1.408 0 2.804 2.804 0 0 1 2.104-.957c2.407-.019 4.66 2.5 4.64 6.51-.013 2.67-.701 5.98-1.793 8.2-.33.67-.691 1.236-1.091 1.63-.265.26-.53.448-.83.448-.18 0-.311-.099-.425-.233a2.444 2.444 0 0 1-.363-.62c-.34-.778-.544-1.78-.758-2.669-.21-.868-.443-1.64-.745-2.127-.376-.603-.873-.888-1.444-.888-.569 0-1.066.284-1.443.885-.306.487-.542 1.26-.755 2.128-.218.89-.423 1.892-.764 2.672a2.5 2.5 0 0 1-.363.622c-.111.13-.237.23-.41.23-.293 0-.552-.189-.815-.448-.397-.394-.759-.961-1.09-1.633-1.094-2.223-1.796-5.539-1.809-8.221v-.001l.001.002Z"
    />
    <path
      fill={color || "url(#b)"}
      d="M8.039 7.058S9.182 8.266 11.5 8.266c2.32 0 3.462-1.208 3.462-1.208a.934.934 0 0 0-1.32-1.32s-.725.66-2.142.66c-1.418 0-2.142-.66-2.142-.66a.934.934 0 0 0-1.32 1.32Z"
    />
    <defs>
      <linearGradient
        id="a"
        x1={2.183}
        x2={23.585}
        y1={22.274}
        y2={5.226}
      >
        <stop stopColor="#00642E" />
        <stop offset="100%" stopColor="#9FC500" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={7.766}
        x2={9.931}
        y1={8.266}
        y2={3.198}
      >
        <stop stopColor="#00642E" />
        <stop offset="100%" stopColor="#9FC500" />
      </linearGradient>
    </defs>
  </svg>
)

export default Dental;