const ToolTip = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clipPath="url(#clip0_2627_76516)">
      <path d="M8 16C12.418 16 16 12.418 16 8C16 3.582 12.418 0 8 0C3.582 0 0 3.582 0 8C0 12.418 3.582 16 8 16ZM8.66667 12H7.33333V6.66667H8.66667V12ZM8 3.83333C8.46 3.83333 8.83333 4.20667 8.83333 4.66667C8.83333 5.12667 8.46 5.5 8 5.5C7.54 5.5 7.16667 5.12667 7.16667 4.66667C7.16667 4.20667 7.54 3.83333 8 3.83333Z" fill="#595F77" />
    </g>
    <defs>
      <clipPath id="clip0_2627_76516">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default ToolTip;