import { SubPersonalInformations } from 'src/types/Situation';
import Info from 'src/assets/Info';
import { countries, phonePrefix } from 'src/data/Form';
import Input from 'src/components/Ui/Input';
import Select from 'src/components/Ui/Select';
import InfoPicto from 'src/assets/InfoPicto';
import Checkbox from 'src/assets/CheckBox';

import styles from './styles.module.css';
import useSubscription from 'src/hooks/useSubscription';

interface MyInformationProps {
  personalInformations: SubPersonalInformations;
  setPersonalInformations: (value: any) => void;
  handleModal: () => void;
}

const MyInformations = ({
  personalInformations,
  setPersonalInformations,
  handleModal,
}: MyInformationProps) => {
  const subscriptionContext = useSubscription();
  const { activity, cities } = subscriptionContext;

  return (
    <div className={styles.section}>
      <span className={styles.iconInfo}>
        <Info />
      </span>
      <h1 className={styles.titleSection}>Vous</h1>
      <label htmlFor="civility" className={`flex items-center ${styles.label}`}>
        Civilité *
      </label>
      <div className="flex flex-row gap-4">
        <button
          className={`${styles.button} ${personalInformations.civility === '2' ? styles.activ : ''}`}
          onClick={() => {
            setPersonalInformations((prevState: SubPersonalInformations) => ({
              ...prevState,
              civility: '2'
            }))
          }}>Madame</button>
        <button
          className={`${styles.button} ${personalInformations.civility === '1' ? styles.activ : ''}`}
          onClick={() => {
            setPersonalInformations((prevState: SubPersonalInformations) => ({
              ...prevState,
              civility: '1'
            }))
          }}>Monsieur</button>
      </div>
      <Input
        label='Nom *'
        value={personalInformations.lastname}
        onChange={(lastname) =>
          setPersonalInformations((prevState: SubPersonalInformations) => ({
            ...prevState,
            lastname
          }))
        }
        id='lastname'
        maxLength={25}
      />
      <Input
        label='Prénom *'
        value={personalInformations.firstname}
        onChange={(firstname) =>
          setPersonalInformations((prevState: SubPersonalInformations) => ({
            ...prevState,
            firstname
          }))
        }
        id='firstname'
        maxLength={15}
      />
      <Input
        label='Date de naissance *'
        value={personalInformations.birthday}
        onChange={(birthday) => 
          setPersonalInformations((prevState: SubPersonalInformations) => ({
            ...prevState,
            birthday
          }))
        }
        id='birthday'
        type='date'
        disabled={personalInformations.birthday !== '' ? true : false}
        maxLength={10}
      />
      <Input
        label="Numéro de rue *"
        value={personalInformations.streetNumber}
        onChange={(streetNumber) =>
          setPersonalInformations((prevState: SubPersonalInformations) => ({
            ...prevState,
            streetNumber
          }))
        }
        id='streetNumber'
        maxLength={4}
      />
      <Input
        label='Adresse *'
        value={personalInformations.streetName}
        onChange={(streetName) =>
          setPersonalInformations((prevState: SubPersonalInformations) => ({
            ...prevState,
            streetName
          }))
        }
        id='streetName'
        maxLength={27}
      />
      <Input
        label="Complément d’adresse"
        value={personalInformations.streetMore}
        onChange={(streetMore) =>
          setPersonalInformations((prevState: SubPersonalInformations) => ({
            ...prevState,
            streetMore
          }))
        }
        id='streetMore'
        maxLength={32}
      />
      <Input
        label='Code postal *'
        value={personalInformations.postalCode}
        onChange={(postalCode) =>
          personalInformations.postalCode !== '' && setPersonalInformations((prevState: SubPersonalInformations) => ({
            ...prevState,
            postalCode
          }))
        }
        id='postalCode'
        disabled={personalInformations.postalCode !== '' && personalInformations.postalCode.length > 5 ? true : false}
        maxLength={5}
      />
      <Select
        label={'Ville *'}
        value={personalInformations.city}
        onChange={(city) =>
          setPersonalInformations((prevState: SubPersonalInformations) => ({
            ...prevState,
            city
          }))
        }
        id='city'
        values={cities.map(({ key, value }) => ({ v: key, l: value }))}
      />
      <Select
        label='Pays *'
        value={personalInformations.country}
        onChange={(country) =>
          setPersonalInformations((prevState: SubPersonalInformations) => ({
            ...prevState,
            country
          }))
        }
        values={countries.map(({ value, label }) => ({ v: value, l: label }))}
        id='country'
      />
      <Input
        label='Email *'
        value={personalInformations.email}
        onChange={(email) =>
          setPersonalInformations((prevState: SubPersonalInformations) => ({
            ...prevState,
            email
          }))
        }
        id='email'
        type='email'
        maxLength={50}
      />
      <div className="flex items-end gap-4 w-full">
        <div className={styles.selectBox}>
          <Select
            label='Téléphone portable *'
            value={personalInformations.phonePrefix}
            onChange={(phonePrefix) => {
              setPersonalInformations((prevState: SubPersonalInformations) => ({
                ...prevState,
                phonePrefix
              }))
            }}
            id="phonePrefix"
            values={phonePrefix.map((prefix) => ({ v: prefix.value, l: prefix.label }))}
          />
        </div>
        <Input
          value={personalInformations.phone}
          onChange={(phone) =>
            setPersonalInformations((prevState: SubPersonalInformations) => ({
              ...prevState,
              phone
            }))
          }
          id='phone'
          maxLength={10}
        />
      </div>
      <div className="flex items-end gap-4 w-full">
        <div className={styles.selectBox}>
          <Select
            label='Téléphone fixe'
            value={personalInformations.phoneFixPrefix}
            onChange={(phoneFixPrefix) => {
              setPersonalInformations((prevState: SubPersonalInformations) => ({
                ...prevState,
                phoneFixPrefix
              }))
            }}
            id="phonePrefix"
            values={phonePrefix.map((prefix) => ({ v: prefix.value, l: prefix.label }))}
          />
        </div>
        <Input
          value={personalInformations.phoneFix}
          onChange={(phoneFix) =>
            setPersonalInformations((prevState: SubPersonalInformations) => ({
              ...prevState,
              phoneFix
            }))
          }
          id='phoneFix'
          maxLength={10}
        />
      </div>

      <Input
        label='Numéro de sécurité sociale *'
        value={personalInformations.insee}
        onChange={(insee) => {
          setPersonalInformations((prevState: SubPersonalInformations) => ({
            ...prevState,
            insee
          }))
        }}
        maxLength={15}
        id='socialNumber'
      />
      <Input
        label='Profession *'
        value={activity.name}
        onChange={() => {}}
        maxLength={20}
        id='activity'
      />
      <Select
        label='Vous êtes sportif ? Bénéficiez de 10% de réduction sur vos cotisations santé*'
        value={personalInformations.athletic}
        disabled={personalInformations.athletic !== '' && personalInformations.athletic.length > 3 ? true : false}
        onChange={(athletic) => {
          setPersonalInformations((prevState: SubPersonalInformations) => ({
            ...prevState,
            athletic
          }))
        }}
        noPlaceholder
        values={[
          { l: 'Oui', v: 'true' },
          { l: 'Non', v: 'false' }
        ]}
        id='sport'
      />
      <div className={styles.advise}>
        <span style={{ height: '24px', width: '24px' }}>
          <InfoPicto />
        </span>
        <span>
          Un justificatif d’inscription à un club de sport devra être fourni au moment de la souscription pour bénéficier de la réduction.
          <span className='underline font-bold cursor-pointer' onClick={handleModal}>Voir les conditions</span>
        </span>
      </div>
      <div className={styles.informations}>
        <span
          onClick={() => setPersonalInformations((prevState: SubPersonalInformations) => ({
            ...prevState,
            check: !personalInformations.check
          }))}
          className='flex items-center justify-center cursor-pointer'
          style={{ height: 18, width: 18, backgroundColor: personalInformations.check ? '#9FC500' : '#fff', borderRadius: 4, border: '1px solid #9FC500' }}>
          <Checkbox />
        </span>
        <span className='flex flex-1'>
          Je déclare adhérer à l’association A.M.V.E.M.
          (Association Mutuelle Verte des Expatriés au Maghreb)
          et j’adhère au contrat collectif de prévoyance complémentaire
          santé à adhésion facultative souscrit par l’ A.M.V.E.M.
          auprès de La Mutuelle Verte en vue de permettre à ses adhérents
          de bénéficier de prestations spécifiques liées à leur statut
          d’expatrié ou de détaché dans l’objectif de compléter les prestations
          en nature servies pas la Caisse des Français à l’Etranger (C.F.E.)
          ou pour les salariés détachés par un organisme d’Assurance Maladie Français.
        </span>
      </div>
    </div>
  )
}

export default MyInformations;