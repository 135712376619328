import { useEffect, useState } from "react";
import {
  SocialSecurityScheme,
  SocialSecuritySchemes,
} from "src/types/Situation";
import { getSocialSecuritySchemes } from "./api";

export const useSocialSecuritySchemesFetcher = (
  situation: string,
  outside: string = "0"
): SocialSecuritySchemes => {
  const [socialSecuritySchemes, setSocialSecuritySchemes] = useState({
    a: [] as SocialSecurityScheme[],
    c: [] as SocialSecurityScheme[],
  } as SocialSecuritySchemes);

  useEffect(() => {
    if (!["0", "1"].includes(outside) || !situation) return;

    getSocialSecuritySchemes(outside, situation).then((data) => {
      setSocialSecuritySchemes({
        a: data["a"],
        c: data["c"],
      });
    });
  }, [outside, situation]);

  return socialSecuritySchemes;
};

/**
 * Checks if a given INSEE number is valid.
 *
 * A valid INSEE number is a string of length 15.
 * @param insee the INSEE number to check
 * @returns true if the INSEE number is valid, false otherwise
 */
export const isInseeValid = (insee: string): boolean => {
  if (insee?.length !== 15) return false;

  let numberString: string = insee.slice(0, 13);
  const letter: string = numberString[6].toLowerCase();

  if (['a', 'b'].includes(letter)) {
    numberString = numberString.replace(letter, '0');
    const adjustment: number = letter === 'a' ? 1000000 : 2000000;
    numberString = (parseInt(numberString, 10) - adjustment).toString();
  }

  const checksum = 97 - (parseInt(numberString, 10) % 97);
  const key: number = parseInt(insee.slice(13));

  return checksum === key;
};
