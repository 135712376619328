const Hospitalization = ({ color }: { color?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M19.5957 8.40625H15.2832V4.09375C15.2832 3.7125 15.1318 3.34687 14.8622 3.07728C14.5926 2.8077 14.227 2.65625 13.8457 2.65625H9.5332C9.15195 2.65625 8.78632 2.8077 8.51674 3.07728C8.24715 3.34687 8.0957 3.7125 8.0957 4.09375V8.40625H3.7832C3.40195 8.40625 3.03632 8.5577 2.76674 8.82728C2.49715 9.09687 2.3457 9.4625 2.3457 9.84375V14.1562C2.3457 14.5375 2.49715 14.9031 2.76674 15.1727C3.03632 15.4423 3.40195 15.5938 3.7832 15.5938H8.0957V19.9062C8.0957 20.2875 8.24715 20.6531 8.51674 20.9227C8.78632 21.1923 9.15195 21.3438 9.5332 21.3438H13.8457C14.227 21.3438 14.5926 21.1923 14.8622 20.9227C15.1318 20.6531 15.2832 20.2875 15.2832 19.9062V15.5938H19.5957C19.977 15.5938 20.3426 15.4423 20.6122 15.1727C20.8818 14.9031 21.0332 14.5375 21.0332 14.1562V9.84375C21.0332 9.4625 20.8818 9.09687 20.6122 8.82728C20.3426 8.5577 19.977 8.40625 19.5957 8.40625ZM19.5957 14.1562H14.5645C14.3738 14.1562 14.191 14.232 14.0562 14.3668C13.9214 14.5016 13.8457 14.6844 13.8457 14.875V19.9062H9.5332V14.875C9.5332 14.6844 9.45748 14.5016 9.32269 14.3668C9.18789 14.232 9.00508 14.1562 8.81445 14.1562H3.7832V9.84375H8.81445C9.00508 9.84375 9.18789 9.76802 9.32269 9.63323C9.45748 9.49844 9.5332 9.31562 9.5332 9.125V4.09375H13.8457V9.125C13.8457 9.31562 13.9214 9.49844 14.0562 9.63323C14.191 9.76802 14.3738 9.84375 14.5645 9.84375H19.5957V14.1562Z" fill={color || "url(#paint0_linear_3130_6500)"} />
    <defs>
      <linearGradient id="paint0_linear_3130_6500" x1="2.3457" y1="21.3438" x2="22.1698" y2="3.95177" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00642E" />
        <stop offset="1" stopColor="#9FC500" />
      </linearGradient>
    </defs>
  </svg>
)

export default Hospitalization;