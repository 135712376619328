import Cross from "src/assets/Cross";
import Modal from "..";

import styles from './styles.module.css';

interface FamilyOfferModalProps {
  close: () => void;
}

const FamilyOfferModal = ({
  close,
}: FamilyOfferModalProps) => (
  <Modal>
    <div className="flex justify-end mb-4">
      <span onClick={close} className="cursor-pointer">
        <Cross />
      </span>
    </div>
    <h3 className={`mb-4 ${styles.title}`}>Bénéficiez d'un mois  offert sur votre cotisation santé !</h3>
    <p className={styles.text}>Votre cotisation santé est offerte pendant un mois pour toute nouvelle souscription individuelle à une complémentaire santé réalisée depuis notre site internet.</p>
    <p className={styles.text}>Cette offre est non cumulable avec d'autres réductions ou avantages accordés et valable pour un engagement d'une durée minimale de 12 mois.</p>
    <p className={styles.text}>L’avantage sera appliqué à partir du 4e mois de la première année d'adhésion.</p>
  </Modal>
)

export default FamilyOfferModal;