const AllMyFamily = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M18 7.16C17.94 7.15 17.87 7.15 17.81 7.16C16.43 7.11 15.33 5.98 15.33 4.58C15.33 3.15 16.48 2 17.91 2C19.34 2 20.49 3.16 20.49 4.58C20.48 5.98 19.38 7.11 18 7.16Z" stroke="url(#paint0_linear_3101_7302)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M16.97 14.4402C18.34 14.6702 19.85 14.4302 20.91 13.7202C22.32 12.7802 22.32 11.2402 20.91 10.3002C19.84 9.59016 18.31 9.35016 16.94 9.59016" stroke="url(#paint1_linear_3101_7302)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.97001 7.16C6.03001 7.15 6.10001 7.15 6.16001 7.16C7.54001 7.11 8.64001 5.98 8.64001 4.58C8.64001 3.15 7.49001 2 6.06001 2C4.63001 2 3.48001 3.16 3.48001 4.58C3.49001 5.98 4.59001 7.11 5.97001 7.16Z" stroke="url(#paint2_linear_3101_7302)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7 14.4402C5.63 14.6702 4.12 14.4302 3.06 13.7202C1.65 12.7802 1.65 11.2402 3.06 10.3002C4.13 9.59016 5.66 9.35016 7.03 9.59016" stroke="url(#paint3_linear_3101_7302)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 14.6307C11.94 14.6207 11.87 14.6207 11.81 14.6307C10.43 14.5807 9.33002 13.4507 9.33002 12.0507C9.33002 10.6207 10.48 9.4707 11.91 9.4707C13.34 9.4707 14.49 10.6307 14.49 12.0507C14.48 13.4507 13.38 14.5907 12 14.6307Z" stroke="url(#paint4_linear_3101_7302)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.91 17.7813C13.32 16.7213 10.69 16.7213 9.09 17.7813C7.68 18.7213 7.68 20.2613 9.09 21.2013C10.69 22.2713 13.31 22.2713 14.91 21.2013" stroke="url(#paint5_linear_3101_7302)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <defs>
      <linearGradient id="paint0_linear_3101_7302" x1="15.33" y1="7.16" x2="20.8039" y2="2.35773" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00642E" />
        <stop offset="1" stopColor="#9FC500" />
      </linearGradient>
      <linearGradient id="paint1_linear_3101_7302" x1="16.94" y1="14.5246" x2="22.2706" y2="9.84525" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00642E" />
        <stop offset="1" stopColor="#9FC500" />
      </linearGradient>
      <linearGradient id="paint2_linear_3101_7302" x1="3.48001" y1="7.16" x2="8.95385" y2="2.35772" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00642E" />
        <stop offset="1" stopColor="#9FC500" />
      </linearGradient>
      <linearGradient id="paint3_linear_3101_7302" x1="2.0025" y1="14.5246" x2="7.33308" y2="9.84526" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00642E" />
        <stop offset="1" stopColor="#9FC500" />
      </linearGradient>
      <linearGradient id="paint4_linear_3101_7302" x1="9.33002" y1="14.6307" x2="14.8039" y2="9.82843" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00642E" />
        <stop offset="1" stopColor="#9FC500" />
      </linearGradient>
      <linearGradient id="paint5_linear_3101_7302" x1="8.0325" y1="22.0038" x2="13.3108" y2="15.6565" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00642E" />
        <stop offset="1" stopColor="#9FC500" />
      </linearGradient>
    </defs>
  </svg>
)

export default AllMyFamily;