import styles from './styles.module.css';

interface ModalProps {
  children: React.ReactNode;
}
const Modal = ({
  children,
}: ModalProps) => (
  <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-40 flex justify-center items-center" style={{ zIndex: 100 }}>
    <div className={styles.modalContainer}>
      {children}
    </div>
  </div>
)

export default Modal;